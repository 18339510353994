const React = require('react');

const QuickList = require('../widgets/QuickList');

const ArticleView = (props) => {
  const { html, toc, print, printUrl } = props;
  const cls = print ? 'print' : '';

  return (
    <main className={cls}>
      <div
        className="article"
        dangerouslySetInnerHTML={{ __html: html }}
      />
      { !print &&
        <a className="print-link" href={printUrl} target="_blank">Print friendly version</a>
      }
      <QuickList items={toc} />
    </main>
  );
};

module.exports = ArticleView;
