const React = require('react')

const TypeLabel = require('../../widgets/TypeLabel');

const DefaultChanged = (props) => {
  const { result, schema1, schema2 } = props;

  const list = result.map((line, n) => {
    const { node1, node2 } = line;
    const def1 = node1.attributes.default
    const def2 = node2.attributes.default
    return (
      <div key={node1.id}>
        {/* <div className="node-path">
          <TypeLabel type={node1.type} short />
          {node2.path}
        </div> */}
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <TypeLabel type={node1.type} short />
              </td>
              <td>
                {node2.path}
              </td>
            </tr>
            <tr>
              <td><b>{schema1}</b></td>
              <td>{def1}</td>
            </tr>
            <tr>
              <td><b>{schema2}</b></td>
              <td>{def2}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  })
  return (
    <div className="api-list">
      <i>Configurations that exist in both schemas but have changed default values:</i>
      {list}
    </div>
  )
};

module.exports = DefaultChanged;