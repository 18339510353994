const React = require('react');
const marked = require('marked');
const { useParams } = require('react-router-dom');
const Prism = require('prismjs');

const { fetchData, replacePaths } = require('../../dataHelper');
const ArticleView = require('./ArticleView');

function loadArticle(file) {
  let url = file.startsWith('http') ? file : `doc/${file}`;
  if (!url.endsWith('.md')) {
    url = url + '.md';
  }

  const download = url.startsWith('http') ? fetch(url) : fetchData(url);
  return download.then(r => r.text())
    .then((markdown) => {
      const html = marked(replacePaths(markdown));
      // marked.setOptions({ baseUrl: 'http://localhost:1234/doc/images/'})
      // const html = marked(markdown);
      return Promise.resolve(html);
    });
}

function jumpToHash() {
  const hash = location.hash.replace('#', '');
  if (hash) {
    document.getElementById(hash)?.scrollIntoView();
  }
}

function findHeadings(selector = 'h2') {
  const els = Array.from(document.querySelectorAll(selector));
  return els.map(el => ({ id: el.id, name: el.innerText }));
}

const Article = (props) => {
  const { folder, article } = useParams();
  const { print } = props;

  const [{ name, html }, setArticle] = React.useState({ name: 'HomePage', html: 'Loading...' });
  const [toc, setToc ] = React.useState([]);

  const externalUrl = new URLSearchParams(location.search).get('ext');

  const newName = externalUrl || (folder ? folder + '/' + article : article);

  // we dont want to parse markdown for every single render, only when article change:
  if (name !== newName) {
    loadArticle(newName)
      .then((html) => {
        setArticle({ name: newName, html });
        Prism.highlightAll();
        setToc(findHeadings());
        jumpToHash();
      })
      .catch(() => setArticle({ name: newName, html: 'Content not found' }));
  }

  const printUrl = `/print/${newName}`;
  // key ensures we scroll to top when loading new articles
  return (
    <ArticleView html={html} key={newName} toc={toc} print={print} printUrl={printUrl} />
  );
}

module.exports = Article;
