const React = require('react');

const Switcher = (props) => {
  const { values, value, onChange } = props;
  const options = values.map((v) => {
    const cls = ['switch', value === v.key ? 'selected' : ''].join(' ');
    const onClick = () => onChange(v.key);

    return (
      <div className={cls} onClick={onClick} key={v.key}>
        {v.value}
      </div>
    );
  });

  return (
    <div>
      Code:
      <div className="switcher">{options}</div>
    </div>
  );
};

module.exports = Switcher;
