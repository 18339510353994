const React = require('react');

const flatten = (tree, list, level = 0) => {
  Object.keys(tree).forEach((name) => {
    const child = tree[name];
    const { children, valuespace, values } = child;
    list.push({ name, level, valuespace, values });
    if (children) {
      flatten(children, list, level + 1);
    }
  })
}

const EventProps = (props) => {
  const children = props.attributes.children;

  const allChildren = [];
  if (children) {
    flatten(children, allChildren);
  }

  const indentPerLevel = 10;

  const properties = allChildren.map((child, i) => {
    const { level, name, valuespace } = child;
    const margin = (level * indentPerLevel) + 'px';
    const values = child.values && '[' + child.values.join(', ') + ']';
    return (
      <div className="param" key={i} style={{ marginLeft: margin }}>
        <div className="name">{name}</div>
        <div>{valuespace} {values}</div>
      </div>
    );
  });

  return (
    <div className="params" style={{ overflowX: 'auto' }}>{properties}</div>
  );
}

module.exports = EventProps;