const React = require('react');
const ReactDOM = require('react-dom');

const App = require('./jsx/App');


/* global document */
ReactDOM.render(
  <App />,
  document.getElementById('app'),
);
