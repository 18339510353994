const React = require('react');

const Support = () => {
  return (
    <main className="center">
      <div className="article">
        <h1>Support</h1>
        Webex Device help is available in a couple of ways to help you with API questions, SDK issues or anything else that's on your mind.

        <div className="box xapi-teams">
          Join the <b>xAPI Devs & Integrations</b> space on Webex Teams for quick replies from both Cisco developer and helpful integrators and 3rd party developers.
          <br />
          <a className="button" href="https://eurl.io/#rkp76XDrG" target="_blank">Join space</a>
        </div>

        <div className="box cisco-forum">
          Join the web based <b>Community forum</b> on community.cisco.com.
          <br />
          <a className="button" href="https://supportforums.cisco.com/t5/telepresence/bd-p/5886-discussions-telepresence" target="_blank">Visit forum</a>
        </div>
      </div>
    </main>
  );
};

module.exports = Support;
