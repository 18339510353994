// quickish way to make a developer mode for using local github content
const isDev = sessionStorage.getItem('devmode');

// call this to set devmode for the rest of the session
window.setDevMode = (devMode = true) => {
  sessionStorage.setItem('devmode', devMode ? '1' : '');
  window.location.reload();
}

function useDatabase() {
  return true;
  const params = new URLSearchParams(location.search);
  return params.has('useDb');
}

const source = isDev
  ? '/roomos.cisco.com/'
  : 'https://raw.githubusercontent.com/cisco-ce/roomos.cisco.com/master/';

const macroSource = isDev
  ? '/roomdevices-macros-samples/'
  : 'https://raw.githubusercontent.com/CiscoDevNet/roomdevices-macros-samples/master/';

if (isDev) {
  console.warn('Fetching external data files locally instead of from github.com');
}

function fetchData(localUrl) {
  console.debug('Fetch file:', source + localUrl);
  return fetch(source + localUrl);
}

function fetchSchemas() {
  if (useDatabase()) {
    return fetch('/api/schema')
      .then(r => r.json())
      .then(r => r.map(i => Object.assign(i, { url: i.name})))
      .then(r => r.filter(s => s.active));
  }
  return fetchData('schemas/schemas.json')
    .then(r => r.json());
}

function fetchSchema(file) {
  if (useDatabase()) {
    const url = '/api/schema/' + file;
    return fetch(url)
      .then(r => r.json())
  }

  const url = 'schemas/' + file;
  return fetchData(url)
    .then(r => r.json());
}

function replacePaths(text) {
  // images starting with src="/  is treated as a relative path
  return text.replace(/src\s*=\s*"(\/)/g, `src="${source}$1`);
}

function getMacros() {
  return fetch(macroSource + 'macro-list.json').then(r => r.json());
}

function getMacroFile(macroName, file) {
  const url = `${macroSource}${macroName}/${file}`;
  return fetch(url)
    .then(r => url.endsWith('json') ? r.json() : r.text())
    .catch(() => console.warn('No manifest found'));
}

function imgUrl(absoluteUrl) {
  return isDev ? absoluteUrl?.replace('https://raw.githubusercontent.com/cisco-ce/roomos.cisco.com/master/', '/roomos.cisco.com/') : absoluteUrl;
}

module.exports = {
  fetchSchemas, fetchSchema, fetchData, replacePaths, getMacros, getMacroFile, macroSource, imgUrl
};
