const React = require('react');
const { NavLink } = require('react-router-dom');

const ValueSpace = (props) => {
  const { type, Values, MinLength, MaxLength, Max, Min } = props.valuespace;
    let values = type || '';
    if (MinLength || MaxLength) values = `<${MinLength} - ${MaxLength}>`;
    else if (Min || Max) values = `<${Min} - ${Max}>`;
    else if (type === 'Literal') values = Values.join(', ');
  return values;
};

function typeName(type) {
  if (!type) return '?';
  return type.replace?.('Literal', '');
}

const CommandParams = (props) => {
  const { params, multiline } = props;
  const list = params.map((param) => {
    const { name, required, valuespace = false, description } = param;
    const type = typeName(valuespace);
    const def = param.default;
    const defaultValue = def ? `Default: ${def}` : '';
    const values = <ValueSpace valuespace={valuespace} />;

    const req = required ? <span className="required">Required</span> : '';
    const info = description && (
      <div className="description">{description}</div>
    );

    return (
      <div key={name} className="param">
        <div className="name">{name}</div>
        <div>{req} {type} {values} {defaultValue} {info}</div>
      </div>
    );
  });
  const multilineInfo = multiline && (
    <div className="note">This is a multi-line command. Raw data can be sent as an unnamed parameter.
    <NavLink to="/doc/TechDocs/xAPI#multiline-commands" target="_blank" style={{ textDecoration: 'underline' }}>
      Read more...
    </NavLink>
  </div>);

  const multiParam = params.some(p => p.valuespace?.type?.includes('Array'));
  const multiParamInfo = multiParam && (
  <div className="note">This command supports parameters that can be used multiple times.
    <NavLink to="/doc/TechDocs/JSXAPIXapiToJs#commands" target="_blank" style={{ textDecoration: 'underline' }}>
      Read more...
    </NavLink>
  </div>);

  const noParams = !params.length && <i>No parameters</i>;
  return (
    <div>
      <div className="params">
        {list}
        {noParams}
      </div>
      {multiParamInfo}
      {multilineInfo}
    </div>
  );
};

module.exports = CommandParams;
