const React = require('react');

const { NavLink } = require('react-router-dom');

const Thumbnail = (props) => {
  const { title, description, image, symbol, link } = props;

  const graphic = image
    ? <div className="image" style={{ backgroundImage: `url(${image})`}} />
    : <div className="symbol">{symbol}</div>;

    return (
    <NavLink className="card" to={link} >
      {graphic}
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </NavLink>
  );
};

module.exports = Thumbnail;
