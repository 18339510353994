const React = require('react');

const PropTable = (props) => {
  const rows = props.rows.map((prop) => {
    const { name, value } = prop;
    return (
      <tr key={name}>
        <td>{name}</td>
        <td title={value}>{value}</td>
      </tr>
    );
  });

  return (
    <table className="props">
      <tbody>
        {rows}
      </tbody>
    </table>
  );
}

module.exports = PropTable;
