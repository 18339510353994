const React = require('react');
const { useLocation } = require('react-router-dom');
const Menu = require('./Menu');


const XapiMenu = (props) => {
  const { domains } = props;
  domains.sort((d1, d2) => d1 < d2 ? -1 : 1);
  const location = useLocation();
  const list = domains.map((path) => {
    const url = '/xapi/domain/?domain=' + path;
    const current = new URLSearchParams(location.search).get('domain');

    return { name: path, link: url, isActive: () => current === path };
  });
  const sections = [{ name: 'Reference', items: list, open: true }];
  return <Menu name="xAPI" sections={sections} />;
};

module.exports = XapiMenu;



