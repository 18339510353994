// For nodes that have no top level container,
// such as xCommand Dial, many of the events etc
// we put them in a special category so they dont
// completely pollute the top level domain list
const orphanPathName = '(Top Level)';

// remove array indexes from path:
function simplePath(path) {
  return path.replace(/\[(.*?)\]/g, '');
}

function findDomains(nodeList) {
  const list = [];
  nodeList.forEach((node) => {
    const domain = simplePath(node.path).split(' ');
    if (domain.length > 1) {
      list[domain[0]] = true;
    };
  });

  const domains = Object.keys(list);
  domains.unshift(orphanPathName);
  return domains;
}

function findProducts(nodeList) {
  const list = new Set();
  nodeList.forEach((node) => {
    node.products.forEach(p => list.add(p));
  });
  return Array.from(list);
}

function findNodesInDomain(nodes, domain) {
  const list = nodes.filter((node) => {
    const paths = simplePath(node.path).split(' ');
    const top = paths.length > 1 ? paths[0] : orphanPathName;
    return top === domain;
  });
  return list;
}

function getDomain(paths) {
  if (!paths.length) return;
  return paths.length > 2 ? paths[1].replace(/\[.*\]/, '') : orphanPathName;
}

function parsePath(path) {
  const parents = simplePath(path).split(' ');
  const name = parents.pop();
  const noParent = orphanPathName;

  return {
    name,
    root: parents[0] || noParent,
    path: parents.join(' '),
  };
}

function findProductNodes(nodes, product) {
  if (product === 'all') return [].concat(nodes);

  return nodes.filter((node) => {
    return node.products.find(p => p === product);
  });
}

function hasFilter(filters, name) {
  return filters[name] && filters[name] != 'all' ? filters[name] : false;
}

function filter(nodes, filters) {
  // console.log('filter', filters);
  const { Type } = filters;
  let result = nodes;

  const type = hasFilter(filters, 'Type');
  if (type) {
    result = result.filter(i => i.type === type);
  }

  const deployment = hasFilter(filters, 'Deployment');
  if (deployment) {
    result = result.filter(i => i.attributes.backend === 'any' || i.attributes.backend === deployment);
  }

  const visibility = hasFilter(filters, 'Visibility');
  if (visibility) {
    result = result.filter(i => i.attributes.access === visibility);
  }

  const user = hasFilter(filters, 'User');
  if (user) {
    result = result.filter(i => i.attributes.role.find(j => j === user));
  }

  const mtr = hasFilter(filters, 'MTR');
  if (mtr) {
    // events do not filter on mtr, just include all:
    result = result.filter(i => i.type === 'Event' || i.attributes.include_for_extension === 'mtr');
  }

  return result;
}

function containsInternal(nodes) {
  return nodes.some(n => n.attributes.access === 'internal');
}

function containsMTR(nodes) {
  return nodes.some(n => n.attributes.include_for_extension === 'mtr');
}

function isVariant(node1, node2) {
  if (!node1 || !node2) {
    return false;
  }
  return node1.path === node2.path && node1.type === node2.type;
}

function removeVariants(nodes) {
  const unique = [];
  let previous;
  nodes.forEach((node) => {
    if (!isVariant(node, previous)) {
      unique.push(node);
    }
    previous = node;
  });
  return unique;
}

function textSearch(nodes, word, fullText = true) {

  const contains = (needle, haystack) => {
    if (!needle || !haystack) return false;
    return haystack.replace(/ /g, '').match(needle);
  };

  return nodes.filter((node) => {
    const attr = node.attributes || {};
    let fromWildcard = word.replace(/\*/g, '.*');// so users can type *, not .*
    fromWildcard = fromWildcard.replace(/ /g, ''); // ignore spaces
    const pattern = new RegExp(fromWildcard, 'i');

    if (contains(pattern, node.type + ' ' + node.path)) return true;
    if (!fullText) return; // only search path name
    if (contains(pattern, attr.description)) return true;
    if (node.type === 'Command') {
      if (attr.params.some(p => contains(pattern, p.name))) return true;
      if (attr.params.some(p => contains(pattern, p.description))) return true;
    }
    if (attr.valuespace && attr.valuespace.Values) {
      if (attr.valuespace.Values.some(v => contains(pattern, v))) return true;
    }
    if (attr.children && contains(pattern, JSON.stringify(attr.children))) return true;
    return false;
  });
}

function findNode(nodes, path, type, product = null) {
  const pathReal = path.replace(/…/g, '..');

  return nodes.find((n) => {
    const hit = n.path === pathReal & n.type === type;
    if (hit) {
      if (product) {
          if (n.products.includes(product)) {
            return true;
          }
      }
      else {
        return true;
      }
    }
  });
}

function sortSchemas(schemas) {
  const normalize = version => version.split('.').map(n => n < 10 ? '0' + n : '' + n).join('.');
  const sorted = [].concat(schemas);
  sorted.sort((v1, v2) => normalize(v1.name) > normalize(v2.name) ? -1 : 1);
  return sorted;
}

module.exports = {
  findDomains, filter, findNodesInDomain, findProducts, findProductNodes, textSearch,
  removeVariants, containsInternal, containsMTR, parsePath, findNode, getDomain, sortSchemas
};
