const React = require('react');

function getVersion(fileName) {
  return fileName.replace('ce-', '').replace('.json', '');
}

const UploadSchema = (props) => {
  const { uploadAll } = props;
  const [files, setFiles] = React.useState([]);

  function onFileRead(fileName, text) {
    const name = getVersion(fileName);
    try {
      const json = JSON.parse(text);
      const nodes = json?.objects?.length;
      if (!nodes) {
        alert(`${fileName} did not contain xAPI nodes`);
        return;
      }
      files.push({ name, json });
      setFiles([...files]);
    }
    catch(e) {
      alert(`${fileName} did not contain valid JSON`);
      console.error(e);
    }
  }

  function readFile(file, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      const text = event.target.result;
      callback(text);
    });
    reader.readAsText(file);
  }

  function fileChanged(e) {
    setFiles([]);
    const list = Array.from(e.target.files);
    list.forEach((file) => {
      readFile(file, (text) => onFileRead(file.name, text));
    });
  }

  const fileList = files.map((file) => {
    const { name, nodes } = file;
    return (
      <div
        key={name}
      ><b>{name}:</b> {nodes} xAPI nodes</div>
    );
  });

  return (
      <div className="widget">
        <h2>Upload xAPI schema</h2>
        <div>Find the JSON files on <a href="https://xapi.cisco.com">xapi.cisco.com</a> (internal only).</div>
        <div>
          <input
            type="file"
            className="schemafile"
            accept=".json"
            multiple
            onChange={fileChanged}
          />
        </div>
        <div>
          {fileList}
        </div>
        <div>
          {files.length ? <button onClick={() => uploadAll(files)}>Upload schemas</button> : null}
        </div>
      </div>
  );
};

module.exports = UploadSchema;