const React = require('react');

const Overlay = require('./widgets/Overlay');

function saveName(name) {
  localStorage.setItem('feedback_name', name);
}

function loadName() {
  return localStorage.getItem('feedback_name') || '';
}

function sendFeedback(name, feedback) {
  saveName(name);

  const url = '/api/feedback';
  const page = location.href;
  const body = JSON.stringify({ name, feedback, page });

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
    .then(r => {
      if (r.ok) {
        return true;
      }
      else {
        throw new Error('Not able to send');
      }
    });
}

class FeedbackForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = { mode: 'idle', text: '', name: '' };
  }

  componentDidMount() {
    this.setState({ name: loadName() });
  }

  render() {
    const { text, name, mode } = this.state;
    const close = () => this.props.close();
    const setText = (e) => this.setState({ text: e.currentTarget.value });
    const setName = (e) => this.setState({ name: e.currentTarget.value });

    const submit = () => {
      this.setState({ mode: 'sending' });
      sendFeedback(name, text)
      .then(() => {
        this.setState({ mode: 'sent' });
      })
      .catch(() => this.setState({ mode: 'error' }));
    };

    const disabled = mode === 'sending';
    const done = mode === 'sent';

    if (done) {
      return (
        <Overlay onClose={close}>
          <div className="dialog feedback">
            Feedback sent. Thanks!
            <br/>
            <button className="primary" onClick={close}>Close</button>
          </div>
        </Overlay>
      );
    }
    const errorMsg = mode === 'error' && <div className="error">Sorry, not able to send 🙈</div>
    const canSend = !disabled && text.length > 5;

    return (
      <Overlay onClose={close}>
        <div className="dialog feedback">
          <form className="form" onSubmit={e => e.preventDefault()}>
            Something missing or not working? Ideas for improvements? Tell us!
            <br />
            <input
              placeholder="Name / email (optional)"
              onChange={setName}
              value={name}
              disabled={disabled}
            />
            <br />
            <textarea
              placeholder="Bugs, ideas, feedback"
              value={text}
              onChange={setText}
              disabled={disabled}
            />
            <br />
            { errorMsg }
            <div>
              <button className="primary" disabled={!canSend} type="submit" onClick={submit}>Submit</button>
              <button onClick={close}>Close</button>
            </div>
          </form>
        </div>
      </Overlay>
    );
  }

};

module.exports = FeedbackForm;
