const React = require('react');

const Select = (props) => {
  const { value, onChange, values } = props;
  const options = values.map(v => <option key={v} value={v}>{v}</option>);

  return (
    <select onChange={e => onChange(e.target.value)} value={value}>
      {options}
    </select>
  );
};

module.exports = Select;
