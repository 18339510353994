const React = require('react');

const XapiBasics = () => {
  return (
    <div className="article">
      <h1>Building Blocks</h1>

      <div>The xAPI is the API language of RoomOS, and it lets you create awesome integrations for Cisco video devices.</div>
      <div>

        <table className="xapi-basics">
          <tbody>
            <tr>
              <td>
                <label className="label Command">Command</label>
                <p/>
                Actions such as <a href="/xapi/Command.Dial">dialing a number</a> and <a href="/xapi/Command.Audio.Microphones.Mute">muting the microphone</a>.
              </td>
              <td>
                <label className="label Status">Status</label>
                <p/>
                Read-only state values of the system, such as whether the system <a href="/xapi/Status.Call[n].Status/">is in call</a> and <a href="/xapi/Status.Audio.Volume/">current volume</a>.
              </td>
              <td>
                <label className="label Configuration">Config</label>
                <p/>
                Configs are permanent system settings like <a href="/xapi/Configuration.SystemUnit.Name/">the name of the video device</a> and <a href="/xapi/Configuration.Audio.DefaultVolume">default system volume</a>.
              </td>
              <td>
                <label className="label Event">Event</label>
                <p/>
                Unlike statuses, events usually occur at singular points in time, such as notifications that a <a href="/xapi/Event.Bookings.Start/">meeting is about to start</a> or that a user <a href="/xapi/Event.UserInterface.Extensions.Panel.Clicked/">pushed a custom button</a>.
              </td>
            </tr>
          </tbody>
        </table>

        <b>Feedback</b> means that you can subscribe to specific APIs you are interested in and get notified when they change. You can subscribe to status changes, configuration changes and events (but not commands).
        {/* <p/>
        <b>What's changed?</b> See which APIs have been added or removed recently with the <a href="/xapi/diff">xAPI comparison tool</a>. */}
        <p/>
        For a good interactive introduction to the xAPI, see <a href="https://developer.cisco.com/learning/lab/collab-xapi-intro/step/1">DevNet's learning tutorials</a>.
      </div>

    </div>
  );
}

module.exports = XapiBasics;
