const React = require('react');

const Select = (props) => {
  const { value, values, onChange } = props;
  let options = [];
  for (const key in values) {
    const opt = <option key={key} value={key}>{values[key]}</option>;
    options.push(opt);
  }

  return (
    <select value={value} onChange={onChange}>{options}</select>
  );
}

const UserParam = (props) => {
  const { id, name, type, info, values, default: def, value, onChange, invalid } = props;
  const change = e => onChange(id, e.target.value);

  let element;
  if (type === 'option') {
    element = <Select value={value} values={values} onChange={change} />;
  }
  else if (type === 'bool') {
    const onTick = e => onChange(id, e.target.checked);
    element = <input type="checkbox" checked={value} onChange={onTick} />;
  }
  else {
    element = <input placeholder={def} value={value} onChange={change} />;
  }

  return (
    <div className={ invalid ? 'invalid' : null}>
      <div className="name">{name}</div>
      <div className="value">
        {element}
      </div>
      <div className="info">{info}</div>
    </div>
  );
};

const UserParams = (props) => {
  const { userParams, setParam } = props;
  // console.log(userParams);
  const rows = userParams.map((param) => {
    return <UserParam key={param.id} onChange={setParam} {...param} />;
  });

  return (
    <div className="form">
      {rows}
    </div>
  );
};

module.exports = UserParams;
