function isSubscribable(path) {
  const exceptions = [
    'MediaChannels Call[n]',
    'MediaChannels DirectShare[n]',
    'RoomAnalytics AmbientTemperature',
    'RoomAnalytics RelativeHumidity',
    'RoomAnalytics AmbientNoiseLevel',
  ];

  return !exceptions.some(exception => path.includes(exception));
}

/**
 * Make all relevant code snippets for a given xAPI node
 */
function quoteIfNecessary(name) {
  return name.includes('.') ? `"${name}"` : name;
}

function getJsCode(node) {
  const { type, path, attributes } = node;
  const path2 = node.path
    .replace(/ /g, '.')
    .replace(/\[n\]/g, '[1]');

  const typeMod = type === 'Configuration' ? 'Config' : type; // jsxapi shortcuts it
  const jsPath = `xapi.${typeMod}.${path2}`;
  const newLine = '\n    ';
  const snippets = [];

  if (type === 'Status') {
    // Promise:
    // const get = `${jsPath}${newLine}.get()${newLine}.then(value => console.log(value));`;
    const get = `const value = await ${jsPath}.get()\nconsole.log(value)`;
    snippets.push({ name: 'Get value', code: get });

    const subscribe = isSubscribable(path)
      ? `${jsPath}${newLine}.on(value => console.log(value));`
      : '⚠️ For performance reasons, you cannot subscribe to this API. If needed you may poll it instead.';

      snippets.push({ name: 'Subscribe', code: subscribe });
}
  else if (type === 'Configuration') {
    // Promise:
    // const get = `${jsPath}${newLine}.get()${newLine}.then(value => console.log(value));`;
    const get = `const value = await ${jsPath}.get()\nconsole.log(value)`;
    snippets.push({ name: 'Get value', code: get });

    const set = `${jsPath}${newLine}.set(value);`;
    snippets.push({ name: 'Set value', code: set });

    const subscribe = `${jsPath}${newLine}.on(value => console.log(value));`;
    snippets.push({ name: 'Subscribe', code: subscribe });
  }
  else if (type === 'Command') {
    const paramsList = attributes.params.map((p) => {
      return `${quoteIfNecessary(p.name)}: value`;
    });
    const params = attributes.params.length || attributes.multiline
      ? `${newLine}{ ${paramsList.join(', ')} }`
      : '';
    const body = attributes.multiline ? `, 'Raw data here...'` : '';
    const invoke = `${jsPath}(${params}${body});`;
    snippets.push({ name: 'Invoke', code: invoke });
  }
  else if (type === 'Event') {
    const subscribe = `${jsPath}${newLine}.on(value => console.log(value));`;
    snippets.push({ name: 'Subscribe', code: subscribe });
  }

  return snippets;
}

function getTshell(node) {
  const { type, path } = node;
  const path2 = node.path
    .replace(/\[n\]/g, '[1]');

  const xPath = `${type}/${path}`
    .replace(/\[n\]/g, '') // feedback doesnt like list ids
    .replace(/ /g, '/');

  const jsPath = `x${type} ${path2}`;
  const snippets = [];

  if (type === 'Status') {
    const get = jsPath;
    snippets.push({ name: 'Get value', code: get });

    const subscribe = `xFeedback Register ${xPath}`;
    snippets.push({ name: 'Subscribe', code: subscribe });
  }
  else if (type === 'Configuration') {
    const get = jsPath;
    snippets.push({ name: 'Get value', code: get });

    const set = `${jsPath}: value`;
    snippets.push({ name: 'Set value', code: set });

    const subscribe = `xFeedback Register ${xPath}`;
    snippets.push({ name: 'Subscribe', code: subscribe });
  }
  else if (type === 'Command') {
    const params = node.attributes.params.map((p) => {
      return `${quoteIfNecessary(p.name)}: value`;
    });
    let invoke = `${jsPath} ${params.join(' ')}`;
    if (node.attributes.multiline) {
      invoke += '\nRaw data here...\nMust end with line with single dot\n.';
    }
    snippets.push({ name: 'Invoke', code: invoke });
  }
  else if (type === 'Event') {
    const subscribe = `xFeedback Register ${xPath}`;
    snippets.push({ name: 'Subscribe', code: subscribe });
  }

  return snippets;
}

function getCloudValue({ Values, type }) {
    if (type === 'Integer')
      return 42;
    if (type === 'IntegerArray')
      return [1, 2, 3];
    if (type === 'Literal')
      return Values.join(' | ');
    if (type === 'LiteralArray')
      return Values;
    if (type === 'String')
      return "value";
    if (type === 'StringArray')
      return ["foo", "bar"];
}


function getCloud(node) {
  const { type } = node;
  const path2 = node.path
    .replace(/ /g, '.')
    .replace(/\[n\]/g, '[1]');

  const snippets = [];
  const webexUrl = 'https://webexapis.com/v1/xapi';
  const configUrl = 'https://webexapis.com/v1/deviceConfigurations';
  const configUrlSet = 'https://developer.webex.com/docs/api/v1/device-configurations/update-device-configurations';
  const statusUrl = 'https://developer.webex.com/docs/api/v1/xapi/query-status';
  const commandUrl = 'https://developer.webex.com/docs/api/v1/xapi/execute-command';
  const docUrl = 'https://developer.webex.com/docs/api/v1/device-configurations/list-device-configurations-for-device';

  if (type === 'Status') {
    const query = `GET ${webexUrl}/status/?deviceId=Y2Ld234...S9mb&name=${path2}`;
    snippets.push({ name: 'Query Status', code: query, url: statusUrl });
  }
  else if (type === 'Configuration') {
    let query = `GET ${configUrl}?deviceId=Y2Ld234...S9mb29&key=${path2}`;
    snippets.push({ name: 'Get value', code: query, url: docUrl });

    let setConfig = `PATCH ${configUrl}?deviceId=Y2Ld234...S9mb29\n`;
    const body = {
      op: 'replace',
      path: path2 + '/sources/configured/value',
      value: 'value',
    };
    setConfig += JSON.stringify(body, null, 2);
    snippets.push({ name: 'Set value', code: setConfig, url: configUrlSet });
  }
  else if (type === 'Command') {
    const params = {};
    for (const param of node.attributes.params) {
      params[param.name] = getCloudValue(param.valuespace);
    };
    const body = {
      deviceId: "Y2lzY29zcGFya.....S9mb29iYXIv",
    };
    if (node.attributes.params.length) {
      body.arguments = params;
    }
    if (node.attributes.multiline) {
      body['body'] = "Raw data here...";
    }

    const invoke = `POST ${webexUrl}/command/${path2}\n${JSON.stringify(body, null, 2)};`;
    snippets.push({ name: 'Invoke Command', code: invoke, url: commandUrl });
  }
  else if (type === 'Event') {
    snippets.push({
      name: 'Only available with Workspace Integrations', url: 'https://developer.webex.com/docs/workspace-integrations',
    });
  }

  return snippets;
}

module.exports = {
  getJsCode,
  getTshell,
  getCloud,
};


/*
From playing around on tshell and macro editor:


Status
  tsh:
    Audio Status Volume
    xfeedback register Status/Audio/Volume

  jsx
    xapi.Status.Audio.Volume.get().then(v => console.log(v));
    xapi.Status.Audio.Volume.on(v => console.log(v));
    list:
    xapi.Status.UserInterface.Extensions.Widget[2].WidgetId.get().then(v => console.log(v));


Config

  tsh:
    xconfiguration Audio DefaultVolume: 50
    xfeedback register Configuration/Audio/DefaultVolume

  jsx:
    xapi.Config.Audio.DefaultVolume.set(44);
    xapi.Config.Audio.DefaultVolume.get().then(v => console.log(v));
    xapi.Config.Audio.DefaultVolume.on(v => console.log(v));
    list:
    xapi.Config.Video.Output.Connector[1].CEC.Mode.get().then(console.log);
    xapi.Config.Video.Output.Connector[1].CEC.Mode.set('Off');
    xapi.Config.Video.Output.Connector[1].CEC.Mode.on(console.log);


Command:

  tsh:
    xcommand Audio Volume Set Level: 33

    multiple params:
    xcommand Presentation Start ConnectorId: 1 ConnectorId: 2

  jsx:
    xapi.Command.Audio.Volume.Set({ Level: 24 });

    multiple params:
    xapi.Command.Presentation.Start({ ConnectorId: [1, 2] });

    w results:
    xapi.Command.Phonebook.Search({ PhonebookType: "Local" }).then(console.log);

*/
