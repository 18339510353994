const React = require('react');
const { useParams } = require('react-router-dom');

const Menu = require('./Menu');
const dataHelper = require('../../dataHelper');

const GuideMenu = (props) => {
  const [menu, setMenu] = React.useState([]);
  const { folder } = useParams();

  React.useEffect(() => {
    dataHelper.fetchData('menus.json')
      .then(r => r.json())
      .then(m => setMenu(m))
      .catch(e => console.error('Not able to fetch menu'));
  }, []);

  menu.forEach((item) => {
    item.active = item.id === folder;
  });

  const { openAll } = props;
  return <Menu name="Guides" sections={menu} openAll={openAll} />;
};

module.exports = GuideMenu;
