const React = require('react');

const { useHistory } = require('react-router-dom');

const { productGroups, keyFromInternal } = require('../../productHelper');
const ApiTypeSelector = require('../widgets/ApiTypeSelector');

/*
Filtes:
- search word
- type (config, cmd, ...)
- version (9.14)
- deployment (cloud, onprem)
- role (integrator, ...)
- visibility (public, internal)
- device (desk pro, ...)
- MTR (extension support)
*/

const SearchDelay = 600;

function replaceSearchParam(search, name, value) {
  const url = new URLSearchParams(search);
  if (value === 'all') {
    url.delete(name);
  }
  else {
    url.set(name, value);
  }
  return '?' + url.toString();
}

const SearchField = (props) => {
  const { searchWord, setSearchWord } = props;
  let history = useHistory();
  const [timer, setTimer] = React.useState();

  // throttle search. we dont want to search 6 times if user enters 6 letters rapidly
  const updateKeyword = e => {
    setSearchWord(e.target.value);
    clearTimeout(timer);
    const id = setTimeout(() => search(e.target.value), SearchDelay);
    setTimer(id);
  }

  const search = (word) => {
    if (word.length > 2) {
      const url = replaceSearchParam(history.location.search, 'search', word);
      history.push(`/xapi/search${url}`);
    }
  }

  return (
    <input
      placeholder="Search xAPI"
      value={searchWord}
      onChange={updateKeyword}
      className="search-input"
    />
  );
}


const TypeFilter = (props) => {
  const { value = 'all', values, onChange } = props;
  let history = useHistory();

  const setType = (type) => {
    const url = replaceSearchParam(history.location.search, 'Type', type);
    history.push(url);
    onChange('Type', type);
  }

  return (
    <ApiTypeSelector value={value} values={values} onChange={setType} />
  )
}

const CheckBox = (props) => {
  const { id, onChange, text, value, title } = props;
  const change = e => onChange(e.target.checked);
  return (
    <div className="checkbox filter" title={title}>
      <input id={id} type="checkbox" checked={value} onChange={change} />
      <label htmlFor={id}>{text}</label>
    </div>
  );
};

const FilterBox = (props) => {
  if (props.name === 'Product') {
    const options = productGroups.map(({ name, products }) => {
      const list = products.map((v) => (
        <option key={v.key} value={v.key}>{v.name}</option>
      ));
      return (
        <optgroup key={name} label={name}>
          {list}
        </optgroup>
      );
    });
    options.unshift(<option key="all" value="all">Any product</option>);

    return <SelectBox {...props} groupedValues={options} />;
  }
  else if (props.name === 'Schema') {
    // TODO: Probably want cloud/onprem to be in database instead
    const cloudPattern = /[\d\.]+ \w+ \d+/;
    const cloud = props.values.filter(value => value.name.match(cloudPattern));
    const onprem = props.values.filter(value => !value.name.match(cloudPattern));
    const listOnprem = onprem.map(op => <option key={op.name} value={op.name}>{op.name}</option>);
    const listCloud = cloud.map(op => <option key={op.name} value={op.name}>{op.name}</option>);

    const options = [
      <optgroup key="cloud" label="Cloud">{listCloud}</optgroup>,
      <optgroup key="onprem" label="On-prem">{listOnprem}</optgroup>,
    ];

    return <SelectBox {...props} groupedValues={options} />;
  }
  else if (props.name === 'Type') {
    return (
      <TypeFilter
        key="Type"
        value={props.value}
        values={props.values}
        onChange={props.onChange} />
    );
  }
  else if (props.name === 'MTR') {
    let history = useHistory();
    const onChange = value => {
      props.onChange('MTR', value);
      const url = replaceSearchParam(history.location.search, 'MTR', value ? 1 : 0);
      history.push(url);
    }
    const value = !!props.value;

    return (
      <CheckBox
        id="mtr-filter"
        key="MTR"
        text="MTR"
        value={value}
        title="Select to show only Microsoft Teams Rooms-compatible APIs"
        onChange={onChange}
      />
    );
  }

  return <SelectBox {...props} />;
}

const SelectBox = (props) => {
  const { onChange, values, value, name, active, groupedValues} = props;
  const choices = groupedValues || values.map(v => <option key={v.key} value={v.key}>{v.name}</option>);
  const history = useHistory();
  const set = e => {
    if (name !== 'Schema') {
      let { value } = e.target;
      if (name === 'Product') {
        value = keyFromInternal(value)
      }
      const url = replaceSearchParam(history.location.search, name, value);

      history.push(url);
    }
    onChange(name, e.target.value);
  };
  const cls = active ? 'active' : '';

  return (
    <div className="filter">
      <select className={cls} onChange={set} value={value}>
        {choices}
      </select>
    </div>
  );
};

const Filters = (props) => {
  const { setOption, filters = [], options, schema, product, searchWord, setSearchWord } = props;

  const list = filters.map((f) => {
    const { name, values } = f;
    let value;
    if (name === 'Schema') value = schema;
    else if (name === 'Product') value = product;
    else value = options[name];
    const active = value && !value?.startsWith?.('all');

    return (
      <FilterBox
        active={active}
        key={name}
        name={name}
        values={values}
        onChange={setOption}
        value={value}
      />
    );
  });


  const dontSubmit = e => e.preventDefault();

  return (
    <form className="search-bar" onSubmit={dontSubmit}>
      <SearchField searchWord={searchWord} setSearchWord={setSearchWord} />
      {list}
    </form>
  );
}

module.exports = Filters;
