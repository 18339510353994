/**
 * TODO:
 *
 * - dialog shows missing file if not connected
 * - possible to mention dynamic panels to remove in manifest (with regex?)
 * - possible to adjust button text (and color and icon) for panels when installing
 * - Dont allow install if more than 10 active macros on system
 */
const React = require('react');

const InstallDialogView = require('./InstallDialogView');
const { getMacroFile, macroSource } = require('../../dataHelper');

function fileName(url) {
  return url.split('/').pop().split('.').shift();
}

function macroUrl(name, file) {
  const isAbsolute = file.startsWith('http:') || file.startsWith('https');
  return isAbsolute ? file : macroSource + name + '/' + file;
}

function getFiles(manifest) {
  const macros = manifest?.profile?.macro?.items || [];
  const uis = manifest?.profile?.roomcontrol?.items || [];

  return { macros, uis };
}

function replaceUserParam(macroText, param) {
  const { id, value, values, type } = param;
  const pattern = new RegExp(`(const|var|let)\\s+${id}(;| |=|$)+.*`, 'm');

  let val = value;
  if  (type === 'string') {
    // if string, need to escape any ' inside the string
    val = `'${value.replace(/'/g, "\\'")}'`;
  }
  else if (type === 'bool') {
    val = value ? 'true' : 'false';
  }
  else if (type === 'option') {
    val = `'${value}'`;
  }
  const replace = `$1 ${id} = ${val};`;

  return macroText.replace(pattern, replace);
}

async function ensureMacrosEnabled(connector) {
  const isEnabled = await connector.macrosAreEnabled();
  if (!isEnabled) {
    await connector.enableMacros();
  }
}

async function installExtension(connector, name, manifest, setMessage) {
  await ensureMacrosEnabled(connector);

  const { macros, uis } = getFiles(manifest);
  const params = manifest?.profile?.userParams || [];

  for (const ui of uis) {
    const { id, payload } = ui;
    const res = await fetch(macroUrl(name, payload));
    const content = await res.text();
    setMessage('Installing UI: ' + id);
    console.log('installing', id);
    await connector.installUIExtension(id, content);
  };

  const sortLibBeforeMacro = (m1, m2) => {
    if (m1.type === 'library') return -1;
    if (m1.type !== 'library' && m1.type === 'library') return 1;
  };
  macros.sort(sortLibBeforeMacro);
  console.log(macros);

  for (const macro of macros) {
    const { payload, type } = macro;
    const activate = type !== 'library';
    const file = fileName(payload);
    const res = await fetch(macroUrl(name, payload));
    let content = await res.text();

    const paramsForMacro = params.filter(p => p.domain === macro.id);
    for (const param of paramsForMacro) {
      content = replaceUserParam(content, param);
    };

    console.log('install', payload);
    setMessage('Installing macro: ' + file);
    // console.log('install:', content);
    await connector.installMacro(file, content, activate);
  };

  if (macros.length) {
    setMessage('Restarting macro runtime');
    await connector.restartMacroRuntime();
  }
}

async function uninstallExtension(connector, name, manifest, setMessage) {
  await ensureMacrosEnabled(connector);

  const { macros, uis } = getFiles(manifest);
  for (const macro of macros) {
    const file = fileName(macro.payload);
    // we skip external libs, these may be shared:
    const isExternal = file.startsWith('http://') || file.startsWith('https://');
    if (!isExternal) {
      setMessage('Uninstalling macro ' + file);
      await connector.removeMacro(file);
    }
  };

  for (const ui of uis) {
    const { id } = ui;
    setMessage('Uninstalling ui', id);
    await connector.removeUIExtension(id);
  };

  if (macros.length) {
    setMessage('Restarting macro runtime');
    await connector.restartMacroRuntime();
  }

  if (manifest.profile.uninstall) {
    setMessage('Cleaning up');
    uninstall(connector, manifest.profile.uninstall);
  }
}

async function uninstall(connector, items) {
  for (const i of items) {
    if (i.type === 'config') {
      await connector.setConfig(i.path, i.value);
    }
    else if (i.type === 'panel') {
      await connector.removeUIExtension(i.id);
    }
  };
}

async function getManifest(name) {
  const manifest = await getMacroFile(name, 'manifest.json');
  if (manifest?.profile?.userParams) {
    manifest.profile.userParams.forEach(u => {
      u.value = u.default || u.values && Object.keys(u.values)[0] || '';
    });
  }
  return manifest;
}

async function isInstalled(manifest, connector) {
  const { macros, uis } = getFiles(manifest);
  let installed = false;
  for (const macro of macros) {
    const has = await connector.hasMacro(macro.id);
    if (has) {
      installed = true;
    }
  };
  return installed;
}

const InstallDialog = (props) => {
  const { name, connector, connection, showDialog } = props;
  const [manifest, setManifest] = React.useState(false);
  const [busy, setBusy] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [installed, setInstalled] = React.useState(false);

  const connected = connection === 'connected';
  React.useEffect(async () => {
    if (connected) {
      const manifest = await getManifest(name);
      setManifest(manifest);
      const inst = await isInstalled(manifest, connector);
      setInstalled(inst);
      const active = await connector.countActiveMacros();
      const MaxActiveMacros = 10;
      if (active >= MaxActiveMacros) {
        setMessage(`Warning: Max number of active macros (${MaxActiveMacros}) is reached `);
      }
    }
  }, []);

  const install = async () => {
    setMessage('Installing extension...');
    setBusy(true);
    try {
      await installExtension(connector, name, manifest, setMessage);
      setMessage('The extension has been installed and activated 🚀');
      setInstalled(true);
    }
    catch(e) {
      setMessage('Something went wrong 🙈');
      console.log(e);
    }
    setBusy(false);
  }

  const uninstall = async () => {
    setBusy(true);
    setMessage('Uninstalling...');
    try {
      await uninstallExtension(connector, name, manifest, setMessage);
      setMessage('The extension was removed ✅');
      setInstalled(false);
    }
    catch(e) {
      setMessage('Something went wrong 🙈');
    }
    setBusy(false);
  }

  const setParam = (id, value) => {
    // console.log('set', id, value);
    const param = manifest.profile.userParams.find(p => p.id === id);
    param.value = value;
    if (param.type === 'number') {
      param.invalid = isNaN(value);
    }
    else if (param.required) {
      param.invalid = !param.value;
    }
    setManifest({...manifest});
  }
  const userParams = manifest?.profile?.userParams;
  const { uis, macros } = getFiles(manifest);

  const base = 'https://' + connector.login?.host;
  const macroEditor =  base + '/web/macros';
  const uiEditor = base +  '/web/roomcontrol';

  return (
    <InstallDialogView
      details={manifest}
      install={install}
      installed={installed}
      uninstall={uninstall}
      showDialog={showDialog}
      busy={busy}
      message={message}
      setParam={setParam}
      userParams={userParams}
      uis={uis}
      macros={macros}
      macroEditor={macroEditor}
      uiEditor={uiEditor}
      {...props}
    />
  );
};

module.exports = InstallDialog;
