const React = require('react');

const ApiTypeSelector = (props) => {
  const { value = 'all', values, onChange } = props;
  const names = { all: 'Any' };

  const buttons = values.map(v => {
    return (
      <button
        key={v.key}
        onClick={() => onChange(v.key)}
        className={value === v.key ? 'active' : ''}
      >{names[v.key] || v.name}</button>
    )
  });

  return (
    <div className="type-buttons filter">
      {buttons}
    </div>
  )
}

module.exports = ApiTypeSelector;