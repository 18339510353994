const React = require('react');

const { NavLink } = require('react-router-dom');

const Logo = () => {
  return (
    <NavLink to="/" className="logo" />
  );
};

const Button = (props) => {
  const { onClick, className, name } = props;
  return (
    <button onClick={onClick} className={className}>{name}</button>
  );
}

const Header = (props) => {
  const { actions, connection } = props;
  const { showDialog } = actions;
  const isXapi = (match, { pathname } ) => pathname.startsWith('/xapi');
  const isGuides = (match, { pathname }) => pathname.startsWith('/doc');
  const showFeedback = () => showDialog('feedback');
  const showConnect = () => showDialog('connect');


  const buttonText = {
    'not-connected': 'Connect', connecting: 'Connecting', connected: 'Connected', failed: 'Connect',
  }[connection];

  return (
    <header>
      <Logo />
      <div className="links">
        <NavLink to="/doc" isActive={isGuides} activeClassName="selected">Guides</NavLink>
        <NavLink to="/xapi" isActive={isXapi} activeClassName="selected">xAPI</NavLink>
        <NavLink to="/macros" activeClassName="selected">Examples</NavLink>
        {/* <NavLink to="/blog">Blog</NavLink> */}
        <NavLink className="wallpapers" to="/images" activeClassName="selected">Wallpapers</NavLink>
        <NavLink className="support" to="/support" activeClassName="selected">Support</NavLink>
      </div>
      <Button className="feedback secondary" name="Feedback" onClick={showFeedback} />
      <Button className="connect primary" name={buttonText} onClick={showConnect} />
    </header>
  );
};

module.exports = Header;
