
function fullPath(node) {
  return `${node.type} ${stripArray(node.path)}`;
}

// remove the content inside brackets (list indexes)
// eg  'Configuration Cameras Camera[1..3] Gamma Mode' => 'Configuration Cameras Camera[] Gamma Mode'
function stripArray(path) {
  return path.replace(/\[.*?\]/g, '[]');
}

function compareObject(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

function compareConfigValuespace(node1, node2) {
  const def1 = node1.attributes.default
  const def2 = node2.attributes.default
  const v1 = node1.attributes.valuespace?.Values;
  const v2 = node2.attributes.valuespace?.Values;

  // console.log('compare', node1.type, node1.path, def1, def2);
  if (v1 && !compareObject(v1, v2)) {
    // console.log(`Change in value space: ${node1.type} ${node1.path}`);
  }

  // compare default:
  // if (def1 !== def2) {
  //   console.log(`Change in default value: ${node1.type} ${node1.path} ${def1} / ${def2}`);
  // }
  return def1 === def2;
}

function compareValueSpaces(schema1, schema2) {
  const diffs = [];

  let n = 0;
  const alreadyAdded = {};

  schema1.forEach((node1) => {
    // just configs for now
    node1.products.forEach((product) => {
      if (node1.type !== 'Configuration') return;
      const node2 = schema2.find(n => n.type === node1.type && n.path === node1.path && n.products.includes(product));
      if (node2) {
        const same = compareConfigValuespace(node1, node2);
        if (!same) {
          if (alreadyAdded[node1.id]) return;
          alreadyAdded[node1.id] = true;

          diffs.push({ type: 'defaultChanged', node1, node2});
        }
        n++
      }
    })
  });

  return diffs;
}


function findNew(schema1, schema2) {
  const newNodes = [];

  const alreadyChecked = {}

  schema1.forEach((node1) => {
    const path = fullPath(node1);

    // dont create multiple entries for identical paths
    if (alreadyChecked[path]) {
      return;
    }
    alreadyChecked[path] = 1

    const inBoth = schema2.find(node2 => {
      return fullPath(node1) === fullPath(node2);
    });

    if (!inBoth) {
      newNodes.push(node1);
    }
  });

  newNodes.sort((n1, n2) => n1.path < n2.path ? -1 : 1)

  return newNodes;
}

function compareSchemas(first, second) {
  const added = findNew(first, second);
  const removed = findNew(second, first);
  return { added, removed };
}

module.exports = { compareSchemas, compareValueSpaces }
