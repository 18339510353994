const React = require('react');

const Menu = (props) => {
  const { setWallpaper, setVirtualBg, url } = props;
  const options = [1, 2, 3];
  const [pos, setPos] = React.useState(1);
  const setVirtual = (e) => setVirtualBg(url, pos);

  return (
    <div className="fade">
      <button className="wallpaper" onClick={() => setWallpaper(url)}>Set wallpaper</button>
      <div className="virtual-bg">
        <button onClick={setVirtual}>Set virtual bg</button>
        <select value={pos} onChange={e => setPos(e.target.value)} >
          {options.map(o => <option key={o} value={o}>{o}</option>)}
        </select>
      </div>
    </div>
  )
}

const ImageBox = ({ img, setWallpaper, connected, setVirtualBg }) => {
  const { small, full, regular } = img.urls;
  const text = img.description || img.alt_description;
  return (
    <div
      className="image-thumbnail"
      style={{ backgroundImage: `url(${small})` }}
      title={text}
    >
      { connected &&
        <Menu url={regular} setWallpaper={setWallpaper} setVirtualBg={setVirtualBg} />
      }
    </div>
  );

}

module.exports = ImageBox;
