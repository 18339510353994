const React = require('react');
const { useParams } = require('react-router-dom');
const marked = require('marked');

const { getMacroFile, macroSource } = require('../../dataHelper');

const MacroProperties = (props) => {
  const { name, url, installExtension } = props;
  const install = () => installExtension(name);

  return (
    <div className="macro-properties">
      <h2>Installation</h2>
      <div>
        <a target="_blank" href={url}>View on GitHub</a>
      </div>
      <div>
        <button className="primary" onClick={install}>Install</button>
      </div>
    </div>
  );
};

const MacroDetails = (props) => {
  const { installExtension } = props;
  const { name } = useParams();
  const [readme, setReadme] = React.useState('');

  const github = 'https://github.com/CiscoDevNet/roomdevices-macros-samples/tree/master/';

  React.useEffect(() => {
    marked.setOptions({ baseUrl: macroSource + name + '/' });

    getMacroFile(name, 'README.md')
      .then(r => setReadme(marked(r)))
      .catch(() => console.warn('No readme found for ', name));
    }, []);

  const text = readme || 'No guide available for this macro.';
  const url = github + name;

  return (
    <main className="macro-details">
      <div className="article" dangerouslySetInnerHTML={{ __html: text }} />
      <MacroProperties
        name={name}
        url={url}
        installExtension={installExtension}
      />
    </main>
  );
};

module.exports = MacroDetails;
