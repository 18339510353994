const React = require('react');
const { useParams } = require('react-router-dom');

const {
  parsePath, findNodesInDomain, filter, textSearch, removeVariants, findNode, getDomain,
} = require('../../schemaHelper');
const { internalFromKey } = require('../../productHelper');
const XapiList = require('./XapiList');
const DetailsView = require('./DetailsView');
const Intro = require('./XapiBasics');

function sortAndGroupNodes(nodes) {
  nodes.sort((p1, p2) => p1.path < p2.path ? -1 : 1);

  const tree = {};
  nodes.forEach((node) => {
    const { name, path } = parsePath(node.path);

    // This effectively merges the list sections to one section instead of one per list index
    // Connector[1-3], Connector[n], ... => Connector[]
    const pathSimple = path.replace(/\[[0-9\.\-n]*\]/g, '[…]');
    if (!tree[pathSimple]) {
      tree[pathSimple] = [];
    }
    tree[pathSimple].push(node);
  });

  const list = [];
  for (key in tree) {
    list.push({ name: key, nodes: tree[key] });
  }
  list.sort((i1, i2) => i1.name < i2.name ? -1 : 1);

  return list;
}

const Xapi = (props) => {
  const { path } = useParams();
  const { nodes, options, setOption, internal } = props;
  const params = new URLSearchParams(location.search);
  const search = params.get('search');
  const domain = params.get('domain');

  const paths = path ? path.replace(/\.\./g, '…').split('.') : [];
  const showDetails = paths.length > 1;
  let fullscreen = !showDetails;
  const { fullTextSearch } = options;

  let nodesToShow;

  // Show search result (doesnt care about domains)
  if (search) {
    // We really shouldnt do this in a view render function
    // but its hard to move it to the store due to the way the router shortcircuits everything

    const filtered = filter(nodes, options);
    const startTime = new Date().getTime();
    nodesToShow = removeVariants(textSearch(filtered, search, fullTextSearch));
    const time = new Date().getTime() - startTime;
    console.debug('Search time (ms):', time);
  }
  // Browse domain (Cameras, Video, UserInterface etc):
  else {
    let parent = domain || getDomain(paths);
    matchingNodes = findNodesInDomain(nodes, parent);
    nodesToShow = removeVariants(filter(matchingNodes, options));
  }

  const list = sortAndGroupNodes(nodesToShow);
  const count = { matching: nodesToShow.length, total: nodes.length };

  let details = null;
  if (showDetails) {
    const type = paths[0];
    const pathWithoutType = paths.slice(1).join(' ');
    const p = params.get('p');
    const product = internalFromKey(p);
    const node = findNode(nodes, pathWithoutType, type, product);
    details = (
      <DetailsView
        node={node}
        nodes={nodes}
        options={options}
        setOption={setOption}
        internal={internal}
      />
    );
  }
  const isBlank = !path && !search && !domain;

  return (
    <XapiList
      {...props}
      key={domain || path}
      list={list}
      search={search}
      nodes={nodes}
      fullscreen={fullscreen}
      count={count}
      details={details}
      intro={isBlank && <Intro />}
    />
  );
}



module.exports = Xapi;
