const React = require('react');
const { NavLink } = require('react-router-dom');

const Dots = (props) => {
  const { total, current } = props;
  const dots = Array.from(Array(total)).map((i, n) => {
    const cls = 'dot' + (current === n ? ' filled' : '');
    return (
      <div key={n} className={cls} />
    );
  });

  return (
    <div className="dots">
      {dots}
    </div>
  );
}

const Carousel = (props) => {
  const { items } = props;
  const [current, setCurrent] = React.useState(props.current);
  const { length } = items;
  const item = items[current];
  const { text, imgUrl, link } = item;
  const next = () => {
    const n = current + 1 >= length ? 0 : current + 1;
    setCurrent(n);
  };
  const previous = () => {
    const p = current - 1 < 0 ? length - 1 : current - 1;
    setCurrent(p);
  }

  return (
    <div className="carousel">
      <button className="arrow left" onClick={previous} />
      <img src={imgUrl} />
      <div className="text">
        <h2>{text}</h2>
        <NavLink to={link}>Start building</NavLink>
      </div>
      <button className="arrow right" onClick={next} />
      <Dots total={items.length} current={current} />
    </div>
  )
}

module.exports = Carousel;
