const React = require('react');

const { sortSchemas } = require('../../schemaHelper');

const SchemaStatus = (props) => {
  const { schemas, remove, setActive, rename } = props;

  const sorted = sortSchemas(schemas);
  const list = sorted.map(s => {
    const toggleActive = (e) => {
      const newState = !s.active;
      setActive(s.name, newState);
    };
    const removeConfirm= () => {
      if (confirm('This will delete the xAPI schema permanently. Are you sure?')) {
        remove(s.name);
      }
    };

    const updated = new Intl.DateTimeFormat('en-GB').format(new Date(s.lastUpdated));
    const promptRename = () => {
      const newName = prompt('Rename schema name?', s.name);
      if (newName && newName !== s.name) {
        rename(s.name, newName);
      }
    }

    return (
      <React.Fragment key={s.name}>
        <div className="schema" onClick={promptRename}>{s.name}</div>
        <label>{updated}</label>
        <input type="checkbox" checked={!!s.active} onChange={toggleActive} />
        <button onClick={removeConfirm}>Delete</button>
      </React.Fragment>
    )
  })

  return (
    <div className="widget">
      <h2>Schema status</h2>
      <div className="schema-table">
        <div>Name</div>
        <div>Uploaded</div>
        <div>User visible</div>
        <div>Remove</div>
        {list.length && list}
      </div>
      {!list.length && <i>No schemas found. Upload some!</i>}
    </div>
  )
}

module.exports = SchemaStatus;
