const jsxapi = require('jsxapi');

const protocol = 'wss://'; // ws wont work from https sites

class Connector {

  xapi = null;
  handler = null;
  login = null;
  unitName = '';
  unitType = '';

  onConnected(xapi) {
    this.xapi = xapi;
    xapi.Config.SystemUnit.Name.get()
      .then(name => this.unitName = name);
    xapi.Status.SystemUnit.ProductPlatform.get()
      .then(type => this.unitType = type);
  }

  connect(login) {
    const { host, username, password } = login;
    this.login = login;

    return new Promise((resolve) => {
      jsxapi.connect(protocol + host, { username, password })
        .on('ready', (xapi) => {
          this.onConnected(xapi);
          resolve(true);
        })
        .on('error', this.onError.bind(this));
    });
  }

  ping() {
    return this.xapi.Status.Audio.Volume.get();
  }

  disconnect() {
    this.xapi?.close();
    this.xapi = null;
  }

  hasNativeXapi() {
    return typeof window.getXAPI === 'function';
  }

  // this is a temporary fix to a weird connection loss when navigating
  // when using native jsxapi (autoconnect) on webex device:
  reconnect() {
    console.log('Verify connection...');
    const timeout = new Promise(resolve => setTimeout(() => resolve(false), 1000));
    Promise.race([ this.ping(), timeout ])
      .then((res) => {
        if (res === false) {
          this.autoConnect();
        }
      });
  }

  autoConnect() {
    return new Promise((resolve, reject) => {
      if (this.hasNativeXapi()) {
        console.log('Auto connecting to device...');
        window.getXAPI()
          .then(xapi => {
            this.onConnected(xapi);
            resolve();
            clearInterval(this.reconnectTimer);
            this.reconnectTimer = setInterval(() => this.reconnect(), 5 * 1000);
          })
          .catch(e => {
            console.warn('Found xapi but not able to connect', e);
            reject();
          });
      }
      else {
        reject();
      }
    });
  }

  setErrorHandler(handler) {
    this.handler = handler;
  }

  onError(e) {
    if (this.handler) {
      this.handler(e);
    }
    console.warn('error:', e);
  }

  getSystemInfo() {
    return Promise.all([
      this.xapi.status.get('UserInterface ContactInfo Name'),
      this.xapi.status.get('SystemUnit ProductPlatform'),
    ])
      .then(([name, device]) => ({ name, device }));
  }

  installWebApp(app) {
    const xml = panelXml
      .replace('$id', app.id)
      .replace('$name', app.name)
      .replace('$url', app.url);

    return this.xapi.command('UserInterface Extensions Panel Save', { PanelId: app.id }, xml);
  }

  removeWebApp(id) {
      return this.xapi.command('UserInterface Extensions Panel. Remove', { PanelId: id });
  }

  async hasMacro(name) {
    const macros = await this.getMacros();
    return macros.Macro?.find(m => m.Name === name);
  }

  hasPanel(panelId) {
    return this.getUIExtensions().then((list) => {
      const item = list.find(i => i.PanelId === panelId);
      return !!item;
    });
  }

  getUIExtensions() {
    return this.xapi.command('UserInterface Extensions List')
      .then(r => r.Extensions.Panel);
  }

  getMacros() {
    return this.xapi.command('Macros Macro Get');
  }

  setSignage(url) {
    this.xapi.config.set('Standby Signage Mode', 'On');
    return this.xapi.config.set('Standby Signage Url', url);
  }

  // type can be Background or HalfwakeBackground
  async setBackgroundImage(Url, type = 'Background') {
    // stupid that we need to to this:
    await this.xapi.command('UserInterface Branding Clear');
    return this.xapi.command('UserInterface Branding Fetch', { Type: type, Url });
  }

  setVirtualBackground(Url, pos = 1) {
    return this.xapi.command('Cameras Background Fetch', { Url, Image: 'User' + pos });
  }

  installBundle(Url, Checksum) {
    const params = { Url };
    if (!Url.startsWith('https')) {
      params.Checksum = Checksum;
    }
    return this.xapi.command('Provisioning Service Fetch', params);
  }

  installMacro(Name, content, activate = true) {
    return this.xapi.command('Macros Macro Save', { Name }, content)
      .then(() => {
        if (activate) {
          this.xapi.command('Macros Macro Activate', { Name });
        }
      });
  }

  enableMacros() {
    return this.xapi.Config.Macros.Mode.set('On');
  }

  countActiveMacros() {
    return this.getMacros()
      .then(list => list.Macro ? list.Macro.filter(m => m.Active === 'True').length : 0);
  }

  macrosAreEnabled() {
    return this.xapi.Config.Macros.Mode.get()
      .then(res => res === 'On');
  }

  restartMacroRuntime() {
    return this.xapi.command('Macros Runtime Restart');
  }

  removeMacro(Name) {
    return this.xapi.command('Macros Macro Remove', { Name });
  }

  installUIExtension(PanelId, content) {
    return this.xapi.command('UserInterface Extensions Panel Save', { PanelId }, content);
  }

  removeUIExtension(PanelId) {
    return this.xapi.command('UserInterface Extensions Panel Remove', { PanelId });
  }

  setSignage(url) {
    return this.xapi.config.set('Standby Signage Url', url);
  }

  setConfig(path, value) {
    return this.xapi.config.set(path, value);
  }
}

module.exports = Connector;
