const React = require('react');

const Icon = (props) => {
  const { type, size = 16 } = props;
  return (
    <span className={`icon icon-${type}_${size}`} />
  );
};

module.exports = Icon;
