const React = require('react');
const { NavLink } = require('react-router-dom');

const Section = require('./Section');

const Menu = (props) => {
  const { name, sections, openAll } = props;
  const list = sections.map((s) => {
    const isActive = () => s.active;
    if (s.link) {
      return <NavLink key={s.link} to={s.link} isActive={isActive} activeClassName="selected">{s.name}</NavLink>;
    }
    else if (s.items) {
      return <Section key={s.name} name={s.name} items={s.items} open={s.open || openAll} />
    }
    else return null;
  });

  return (
    <menu>
      <h1>{name}</h1>
      {list}
    </menu>
  );
};

module.exports = Menu;
