const React = require('react');

const Overlay = require('./Overlay');

const Dialog = (props) => {
  return (
    <Overlay onClose={props.onClose}>
      <div className="dialog">{props.children}</div>
    </Overlay>
  );
};

module.exports = Dialog;
