const React = require('react');
const { useState } = require('react');

const dataHelper = require('../../../dataHelper');
const { compareSchemas, compareValueSpaces } = require('./diffHelper');
const Select = require('../../widgets/Select');
const ResultView = require('./ResultView');
const DefaultChanged = require('./DefaultChanged');
const FileInput = require('./FileInput');
const RadioGroup = require('./RadioGroup');


const XapiDiff = (props) => {
  const { schemas } = props;
  if (!schemas.length) return null;

  const names = schemas.map(s => s.name);

  const [first, setFirst] = useState(schemas[0].name);
  const [second, setSecond] = useState(schemas[1].name);
  const [busy, setBusy] = useState(false);
  const [result, setResult] = useState(null);
  const [customJson, setCustomJson] = useState(null);
  const [type, setType] = useState('basic');

  const list1 = <Select onChange={setFirst} value={first} values={names} />;
  const list2 = <Select onChange={setSecond} value={second} values={names} />;

  const compare = () => {
    setResult(null);
    setBusy(true);

    // wait for busy state to be visible in ui
    setTimeout(async () => {
      try {
        const schema1 = customJson || await dataHelper.fetchSchema(first);
        const schema2 = await dataHelper.fetchSchema(second);
        const result = type === 'basic'
          ? compareSchemas(schema1.objects, schema2.objects)
          : compareValueSpaces(schema1.objects, schema2.objects);

        setResult(result);
        // reset file input, so it's possible to load the same file again
        if (customJson) {
          document.getElementById('custom-json-file').value = '';
        }
      }
      catch(e) {
        console.error(e);
      }
      setBusy(false);
    }, 10)
  }

  const customFile = new URLSearchParams(location.search).get('custom');
  const sameSame = first === second;
  const disabled = busy || !customFile && sameSame || (customFile && !customJson);

  let resultView = null;
  if (result) {
    const name = customJson ? 'Custom' : first
    if (type === 'basic') {
      resultView = <ResultView {...result} schema1={name} schema2={second} />
    }
    else {
      resultView = <DefaultChanged schema1={name} schema2={second} result={result} />;
    }
  }

  const fileInput = <FileInput onFileInput={data => setCustomJson(data)} />
  const types = [
    { id: 'basic', name: 'Added / removed APIs' },
    { id: 'config-default', name: 'xConfig default changes' }
  ];

  const changeType = (type) => {
    setResult(null);
    setType(type);
  };

  const radios = <RadioGroup value={type} values={types} onChange={changeType} />

  return (
    <main className="center">
      <div className="article xapi-diff">
        <h1>xAPI diff</h1>
        Compare xAPI schemas and see which APIs were added or removed between the two versions.
        <div className="diff-form">
          {customFile ? fileInput : list1}
          {list2}
          <button className="primary" onClick={compare} disabled={disabled}>Compare</button>
        </div>
        <details>
          <summary>Advanced</summary>
          <div>{radios}</div>
        </details>
        {busy && <section><i>Comparing APIs...</i></section>}
        {resultView}

      </div>
    </main>
  )
}

module.exports = XapiDiff;
