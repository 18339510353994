const React = require('react');

const Overlay = (props) => {
  const touch = (e) => {
    if (e.target.classList.contains('overlay')) {
      props.onClose();
    }
  };

  return <div className="overlay" onClick={touch}>{props.children}</div>
}

module.exports = Overlay;
