// What's needed before first push:
// - front page for guides
//   - users - take a look at the use cases, get admin access and install extensions
//   - it admins: look at release notes
//   - developers: read the tech docs, browse the apis, look at the extensions

const React = require('react');
const { useParams } = require('react-router-dom');
const { fetchData, imgUrl } = require('../../dataHelper');

const Thumbnail = require('./Thumbnail');

const ArticleList = (props) => {
  const { list } = props;
  const thumbs = list.filter(i => i.id).map((item) => {
    const { id } = item;
    return (
      <Thumbnail
        key={id}
        {...item}
      />
    );
  });

  return (
    <main>
      <div className="page">
        <h1>Articles</h1>
        <div className="articles">
          {thumbs}
        </div>
      </div>
    </main>
  );
};

/* abstracts away the react router stuff */
const Handler = (props) => {
  const { folder } = useParams();
  const [articles, setArticles] = React.useState([]);
  React.useEffect(async () => {
    try {
      const res = await fetchData('articles.json');
      const list = await res.json();
      setArticles(list);
    }
    catch(e) {
      console.log('not able to fetch articles', e);
    }
  }, []);

  const list = articles[folder] || [];
  list.forEach(item => {
    item.link = '/doc/' + folder + '/' + item.id;
    item.image = imgUrl(item.image);
  })

  return <ArticleList list={list} />;
}

module.exports = Handler;