const React = require('react');

const PropTable = require('./PropTable');

function makeList(values, descriptions) {
  return values.map(name => ({ name, value: descriptions[name] }));
}

function hasKids(obj) {
  return obj && Object.keys(obj).length > 0;
}

const ValueSpace = (props) => {
  const { attributes } = props;
  const { type, Values, MinLength, MaxLength, Max, Min, description } = attributes.valuespace;
  let values = type || '';
  if (MinLength || MaxLength) values = `<${MinLength} - ${MaxLength}>`;
  else if (Min || Max) values = `<${Min} - ${Max}>`;
  else if (type === 'Literal') values = Values.join(', ');

  const rows = [];
  // API has a description for each value enum:
  if (hasKids(description)) {
    makeList(Values, description).forEach(item => rows.push(item));
  }
  else {
    rows.push({ name: 'Value space', value: values });
  }

  if (attributes.default) {
    rows.push({ name: 'Default value', value: attributes.default });
  }
  return <PropTable rows={rows} />;
}

module.exports = ValueSpace;
