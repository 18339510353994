const React = require('react');
const { NavLink } = require('react-router-dom');

const Section = (props) => {
  const { name, items, open } = props;
  const [isOpen, setOpen] = React.useState(open);
  const children = items.map(i => (
    <NavLink
      key={i.name}
      to={i.link}
      isActive={i.isActive}
      activeClassName="selected">{i.name}</NavLink>
  ));
  const toggle = () => setOpen(!isOpen);
  const cls = ['section', isOpen ? 'open' : ''].join(' ');

  return (
    <React.Fragment>
      <a className={cls} onClick={toggle}>{name}</a>
      { isOpen && 
        <div className="sub-menu">
          {children}
        </div>
      }
    </React.Fragment>
  )
};

module.exports = Section;
