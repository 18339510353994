const React = require('react');

const RadioGroup = (props) => {
  const { value, values, name, onChange } = props;
  const buttons = values.map(v => {
    const checked = value === v.id;
    return (
      <label key={v.id}>
        <input
          type="radio"
          name={name}
          checked={checked}
          onChange={e => onChange(v.id)}
        />
        {v.name}
      </label>
    );
  })

  return (
    <>{buttons}</>
  );
}

module.exports = RadioGroup;
