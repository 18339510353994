const React = require('react');

const FileInput = (props) => {
  const { onFileInput } = props

  const readFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      try {
        const json = JSON.parse(reader.result);
        if (!json?.objects?.length) {
          alert('Not able to find xAPI nodes in file. Not a schema file?');
        }
        else {
          console.log('loaded apis', json.objects.length)
          onFileInput(json);
        }
      }
      catch(e) {
        console.error(e);
        alert('Not able to read file as JSON');
      }
    }
    reader.readAsText(file);
  }

  return <input id="custom-json-file" type="file" accept=".json" onChange={readFile} />
}

module.exports = FileInput;