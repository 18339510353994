// const type = [
//   { key: '', name: '' },
// ];

const users = [
  { key: 'all', name: 'Any role' },
  { key: 'Admin', name: 'Admin' },
  { key: 'User', name: 'User' },
  { key: 'Integrator', name: 'Integrator' },
  { key: 'RoomControl', name: 'Room Control' },
];

const backends = [
  { key: 'all', name: 'Any back-end' },
  { key: 'cloud', name: 'Cloud' },
  { key: 'onprem', name: 'On-premise' },
];

const types = [
  { key: 'all', name: 'Any type' },
  { key: 'Command', name: 'Command' },
  { key: 'Configuration', name: 'Config' },
  { key: 'Status', name: 'Status' },
  { key: 'Event', name: 'Event' },
];

const visibilities = [
  { key: 'all', name: 'Any visibility' },
  { key: 'public-api', name: 'Public' },
  { key: 'public-api-preview', name: 'Preview 🔥' },
  { key: 'internal', name: 'Internal 🔒' },
];

module.exports = { users, types, visibilities, backends };
