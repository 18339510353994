const React = require('react');
const { NavLink } = require('react-router-dom');

const TypeLabel = require('../widgets/TypeLabel');
const QuickList = require('../widgets/QuickList');

function autoscroll(path) {

  setTimeout(() => {
    const node = document.getElementById(path);
    if (node) {
      node.scrollIntoView();
    }
  }, 10); // cant do this while rendering
}

const XapiList = (props) => {
  const {
    options, setOption, list, fullscreen, searchBar, search, count,
    intro, details
  } = props;

  const nameToId = name => name.replace(/ /g, '-');
  const quickLinks = list.map(i => ({ name: i.name, id: nameToId(i.name) }));
  const currentPath = location.pathname
    .replace('/xapi/', '')
    .replace(/\//, '');

  const quickList = !details
    && <QuickList items={quickLinks} />;

    const content = list.map((group) => {
    const nodes = group.nodes.map((node) => {
      const { path = '', type, id, attributes = {} } = node;
      const { access } = attributes;
      let symbol = '';
      if (access === 'public-api-preview') symbol = '🔥';
      else if (access === 'internal') symbol = '🔒';
      const urlPath = type + '.' + path.split(' ').join('.');

      const selected = urlPath === currentPath;
      const url = search
        ? `/xapi/${urlPath}/?search=` + search
        : `/xapi/${urlPath}/`;
      const cls = ['node-path', selected ? 'selected' : ''].join(' ');

      return (
        <div key={id} id={urlPath} className={cls}>
          <TypeLabel short type={type} />
          <NavLink activeClassName="selected" to={url}>{path}</NavLink> {symbol}
        </div>
      );
    });

    return (
      <div key={group.name} id={nameToId(group.name)}>
        <section>{group.name}</section>
        {nodes}
      </div>
    );
  });

  const { fullTextSearch } = options;
  const onFullText = e => setOption('fullTextSearch', e.target.checked);

  const textSearchOption = (
    <div>
      <input type="checkbox" id="fulltext" checked={fullTextSearch} onChange={onFullText} />
      <label htmlFor="fulltext">Full text search (descriptions, parameters, etc)</label>
    </div>
  );
  const listStyle = fullscreen ? { width: '100%' } : {};

  const msg = intro
    ? <div>{intro}</div>
    : <div>Items: <b>{count.matching}</b> (of {count.total})</div>;

  autoscroll(currentPath);

  return (
    <main className="main">
      <div className="api-list" style={listStyle}>
        {search && textSearchOption}
        {msg}
        {content}
      </div>
      {details}
      {searchBar}
      {quickList}
    </main>
  );
};


module.exports = XapiList;
