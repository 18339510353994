const React = require('react');

const TypeLabel = (props) => {
  const { type, short } = props;
  const names = { Configuration: 'CFG', Status: 'STA', Command: 'CMD', Event: 'EVT' };
  const name = short ? names[type] : type === 'Configuration' ? 'Config' : type;
  const cls = ["label " + type, short ? 'short' : ''].join(' ');

  return <label title={type} className={cls}>{name}</label>;
};

module.exports = TypeLabel;
