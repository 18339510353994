const React = require('react');

const UploadSchema = require('./UploadSchema');
const SchemaStatus = require('./SchemaList');

function fetchSchemas() {
  return fetch('/api/schema').then(r => r.json());
}

function removeSchema(name) {
  return fetch('/api/admin/schema/' + name, { method: 'DELETE' });
}

function post(url, body) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  return fetch(url, options);
}

function getDbStatus() {
  return fetch('/api/admin/db').then(r => r.json());
}

function connectDb(index) {
  return post('/api/admin/db', { dbIndex: index });
}

function upload(name, schema) {
  return post('/api/admin/schema/' + name, { schema });
}

function setActive(name, active) {
  return post('/api/admin/schema/' + name, { active });
}

function renameSchema(oldName, newName) {
  if (!newName || !oldName) return;

  return post('/api/admin/schema/' + oldName, { newName });
}

const Admin = (props) => {
  const [schemas, setSchemas] = React.useState([]);
  const [status, setStatus] = React.useState(null);
  const [currentDb, setCurrentDb] = React.useState(1);
  const { setNotification, hideNotification } = props.actions;

  const update = async () => {
    const schemas = await fetchSchemas();
    setSchemas(schemas);
    const status = await getDbStatus();
    setStatus(status);
  };

  const uploadAll = async (files) => {
    setNotification('Uploading');
    for (const file of files) {
      try {
        setNotification('Uploading ' + file.name);
        await upload(file.name, file.json);
      }
      catch(e) {
        console.log(e);
        alert('Not able to upload ' + file.name);
      }
    }
    hideNotification();
    update();
  };

  const rename = (oldName, newName) => {
    renameSchema(oldName, newName);
    update();
  };

  const remove = async (name) => {
    await removeSchema(name);
    update();
  };

  const active = async (name, active) => {
    await setActive(name, active);
    update();
  };

  React.useEffect(update, []);

  const dbStatus = status === null ? 'Checking...' : (status?.connected ? '💚 Connected' : '🔴 Not connected');
  const reconnectDb = async () => {
    setStatus(null);
    await connectDb(currentDb);
    const status = await getDbStatus();
    setStatus(status);
  }

  return (
    <main className="center admin">
      <div className="form">
        <SchemaStatus
          schemas={schemas}
          setActive={active}
          rename={rename}
          remove={remove}
        />
        <UploadSchema
          uploadAll={uploadAll}
        />
      </div>
      <div>
        DB status: {dbStatus}
        <select value={currentDb} onChange={e => setCurrentDb(e.target.value)}>
          <option value="1">DB 1</option>
          <option value="2">DB 2</option>
          <option value="3">DB 3</option>
        </select>
        <button onClick={reconnectDb}>Reconnect</button>
      </div>

    </main>
  );
};

module.exports = Admin;
