const React = require('react');
const Prism = require('prismjs');

const Switcher = require('../widgets/Switcher');
const codeHelper = require('../../codeHelper');

const Code = (props) => {
  const { code } = props;
  const copy = () => {
    const el = document.querySelector('#copy-paste');
    el.value = code;
    el.select();
    document.execCommand('copy');
    document.getSelection().removeAllRanges();
  }

  const lang = 'language-javascript';
  const html = Prism.highlight(code, Prism.languages.javascript, 'javascript');

  return (
    <div className="code">
      <pre className={lang}>
        <code className={lang} dangerouslySetInnerHTML={{ __html: html }} />
      </pre>
      <button onClick={copy} className="copy">Copy</button>
    </div>
  );
};

const Snippet = (props) => {
  const { snippet } = props;

  return (
    <div>
      <div>{snippet.name}
      { snippet.url && 
        <div><a href={snippet.url} target="_blank">🌍 See more details here</a></div>
      }
</div>
      {
        snippet.code &&
          <Code code={snippet.code} />
      }
    </div>
  )
}

const CodePage = (props) => {
  const { node, format, setFormat } = props;

  const generators = {
    js: codeHelper.getJsCode,
    tshell: codeHelper.getTshell,
    cloud: codeHelper.getCloud,
  };

  const generator = generators[format] || generators['js'];
  const snippets = generator(node);
  const codes = snippets.map(s => <Snippet key={s.name} snippet={s} />);
  const formats = [
    { key: 'js', value: 'JavaScript' },
    { key: 'tshell', value: 'Command line' },
    { key: 'cloud', value: 'Webex Cloud' },
  ];

  return (
    <div className="api-code">
      <Switcher values={formats} value={format} onChange={setFormat} />
      {codes}
    </div>
  );
};

module.exports = CodePage;
