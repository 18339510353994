// TODO
// * Proper unsplash app / key
// * Show install buttons when tapping image
// * Possible to see image larger
// * Button to launch self view maximied and select virtual bg (for quick preview)
//
// * Show error msg when image is too large - allow to try smaller
// * x to remove search word
//
// * Remember recents
// * Show others used from unsplash
const React = require('react');

const Image = require('./ImageBox');

const fakeResult = [
  'https://images.unsplash.com/photo-1523805009345-7448845a9e53?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNzkxNDN8MHwxfHNlYXJjaHwzfHxhZnJpY2F8ZW58MHwwfHx8MTYzOTUwNjA1MA&ixlib=rb-1.2.1&q=80&w=1920',
  'https://images.unsplash.com/photo-1484318571209-661cf29a69c3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNzkxNDN8MHwxfHNlYXJjaHw0fHxhZnJpY2F8ZW58MHwwfHx8MTYzOTUwNjA1MA&ixlib=rb-1.2.1&q=80&w=1920',
  'https://images.unsplash.com/photo-1489392191049-fc10c97e64b6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNzkxNDN8MHwxfHNlYXJjaHw3fHxhZnJpY2F8ZW58MHwwfHx8MTYzOTUwNjA1MA&ixlib=rb-1.2.1&q=80&w=1920',
  'https://images.unsplash.com/photo-1511185307590-3c29c11275ca?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNzkxNDN8MHwxfHNlYXJjaHwxMHx8YWZyaWNhfGVufDB8MHx8fDE2Mzk1MDYwNTA&ixlib=rb-1.2.1&q=80&w=1920',
  'https://images.unsplash.com/photo-1511283878565-0833bf39de9d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNzkxNDN8MHwxfHNlYXJjaHwyNHx8YWZyaWNhfGVufDB8MHx8fDE2Mzk1MDYwNTA&ixlib=rb-1.2.1&q=80&w=1920',
].map(i => ({ urls: { small: i, regular: i, full: i }, alt_description: 'Photo here' }));


async function searchImages(keyword) {
  const useFake = location.search.includes('fake');
  if (useFake) {
    return fakeResult;
  }

  const res = await fetch('/api/images/' + keyword);
  const list = await res.json();
  return list && list.results;
}

const ImageSearch = (props) => {
  const { connector, connection, actions } = props;
  const { setNotification, hideNotification } = actions;
  const [images, setImages] = React.useState([]);
  const [keyword, setKeyword] = React.useState('wallpaper');

  React.useEffect(() => {
    search();
  }, []);

  const connected = connection === 'connected';

  const runTask = async (task) => {
    try {
      setNotification('Installing...');
      await task;
      setNotification('Done!');
      setTimeout(hideNotification, 3000);
    }
    catch(e) {
      setNotification('Not able to install image. Too large (max is 8 MB)?', 'error');
      setTimeout(hideNotification, 5000);
    }
  }

  const setWallpaper = (url) => runTask(connector.setBackgroundImage(url));
  const setVirtualBg = (url, pos) => runTask(connector.setVirtualBackground(url, pos));

  const resultList = images.map((img, i) => {
    return (
      <Image
        img={img}
        key={i}
        connected={connected}
        setWallpaper={setWallpaper}
        setVirtualBg={setVirtualBg}
      />
      )
  });


  const search = () => {
    if (keyword.startsWith('http')) {
      const url = keyword.trim();
      setImages([ {
        urls: {
          small: url, regular: url, full: url,
        }
      }]);
    }
    else {
      searchUnsplash();
    }
  };

  const searchUnsplash = async () => {
    if (!keyword.trim()) return;
    try {
      setNotification('Searching...');
      const images = await searchImages(keyword);
      setImages(images);
      hideNotification();
    }
    catch(e) {
      setNotification('Sorry, not able to search Unsplash right now');
    }
  };

  return (
    <div className="image-search">
      <form onSubmit={e => e.preventDefault()}>
        <input
          placeholder="Search for images"
          onChange={e => setKeyword(e.target.value)}
          className="search"
        />
        <button onClick={search}>Search</button>
      </form>
      {!connected &&
        <div className="warning">Connect to a video device to install wallpapers and virtual backgrounds</div>
      }
      {!!resultList.length &&
        <div>Results from Unsplash.com:</div>
      }
      <div className="gallery">{resultList}</div>

    </div>
  );
};

module.exports = ImageSearch;
