const React = require('react');
const { useState } = require('react');
const { NavLink } = require('react-router-dom');

const TypeLabel = require('../../widgets/TypeLabel');
const ApiTypeSelector = require('../../widgets/ApiTypeSelector');

const apiTypes = [
  {
      "key": "all",
      "name": "Any type"
  },
  {
      "key": "Command",
      "name": "Command"
  },
  {
      "key": "Configuration",
      "name": "Config"
  },
  {
      "key": "Status",
      "name": "Status"
  },
  {
      "key": "Event",
      "name": "Event"
  }
]

const List = (props) => {
  const { nodes, link = true, schema } = props;
  const list = nodes.map(node => {
    const { type, path } = node;
    const fullPath = `${type} ${path}`;
    const urlPath = type + '.' + path.split(' ').join('.');

    const url = `/xapi/${urlPath}/?schema=${schema}`;
    const title = link ? <NavLink target="apis" to={url}>{node.path}</NavLink> : node.path

    return (
      <div key={fullPath} className="node-path">
        <TypeLabel type={node.type} short />
        {title}
      </div>
    );
  });

  if (!list.length) {
    return <i>No entries.</i>
  }

  return list;
}

function filterList(list, type, name) {
  const byType = type === 'all'
    ? list.filter(n => n)
    : list.filter(n => n.type === type);

  const byWord = name
    ? byType.filter(n => n.path.toLowerCase().includes(name.toLowerCase()))
    : byType;

  return byWord
}

const ResultView = (props) => {
  const { added, removed, schema1, schema2 } = props;

  const [apiType, setApiType] = useState('all');
  const [name, setName] = useState('');

  const addFiltered = filterList(added, apiType, name);
  const removedFiltered = filterList(removed, apiType, name);

  const addedList = <List nodes={addFiltered} schema={schema1} />
  const removedList = <List nodes={removedFiltered} schema={schema2} />

  return (
    <div className="api-list">
      <div className="search-bar">
        Filter:
        <input
          className="search-input"
          placeholder="API name"
          onChange={e => setName(e.target.value)}
        />
        <ApiTypeSelector value={apiType} values={apiTypes} onChange={setApiType} />
      </div>
      <section>
        <i>In <b>{schema1}</b>, but not in <b>{schema2}</b> ({addFiltered.length}):</i>
        {addedList}
      </section>

      <section>
        <i>In <b>{schema2}</b>, but not in <b>{schema1}</b> ({removedFiltered.length}):</i>
        <p/>
        {removedList}
      </section>
    </div>
  );
};

module.exports = ResultView;