const React = require('react');

const Notification = (props) => {
  const { text, type} = props;
  const cls = ['notification', type].join(' ');

  return text
    ? <div className={cls}>{text}</div>
    : null;
};

module.exports = Notification;
