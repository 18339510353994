const React = require('react');

const Dialog = require('../widgets/Dialog');
const UserParams = require('./UserParams');

const FileList = (props) => {
  const { macros, uis } = props;
  const all = macros.concat(uis);
  const files = all.map((file) => {
    const full = file.payload;
    const name = full.length > 16
      ? full.substr(0,8) + '…' + full.substr(-10)
      : full;

      return <span style={{ margin: '0 0.3em' }} target="_blank" title={full} key={full}>{name}</span>;
  });

  return (
    <div style={{ background: '#eee', width: '100%', padding: '0.5em' }}>
      <b>Files:</b> {files}
    </div>
  );
}

const InstallDialogView = (props) => {
  const {
    connection, close, install, uninstall, busy, userParams, setParam, details, message,
    installed, uis, macros, showDialog, macroEditor
  } = props;
  const connected = connection === 'connected';

  if (!connected) {
    return (
      <Dialog onClose={close}>
        <div>Connect to your video device first to easily install extensions.
          <button onClick={() => showDialog('connectAndInstall')}>Connect</button>
        </div>
      </Dialog>
    );
  }
  else if (!details) {
    return (
      <Dialog onClose={close}>
        <h2>Missing installation file</h2>
        <div>Please visit the GitHub page for this extension and install the files manually.</div>
      </Dialog>
    )
  }
  const isInvalid = userParams && userParams.some(p => p.invalid);
  const installText = installed ? 'Re-install' : 'Install';

  return (
    <Dialog onClose={close}>
      <h2>Install extension</h2>
      <FileList macros={macros} uis={uis} />
      {connected && userParams && <UserParams userParams={userParams} setParam={setParam} /> }
      {connected &&
        <div style={{ width: '100%', textAlign: 'center' }}>
          <button
            className="primary"
            disabled={busy || isInvalid}
            onClick={install}>{installText}</button>
          {installed &&
            <button className="danger" disabled={busy} onClick={uninstall}>Uninstall</button>
          }
          <div className="status-message">
            {message}
            {installed &&
              <div>Hack the extension in the&nbsp;
                <a
                  href={macroEditor}
                  target="editor"
                  style={{ textDecoration: 'underline' }}>macro editor</a>
              </div>
            }
          </div>
        </div>
      }
    </Dialog>
  );
};

module.exports = InstallDialogView;
