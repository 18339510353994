const React = require('react');

const Carousel = require('./misc/Carousel');

// TODO: move to content repo instead
const usecases = [
  {
    id: 'space-management',
    text: 'Monitor how and when your spaces are being used to optimize your office resources',
    imgUrl: 'https://raw.githubusercontent.com/cisco-ce/roomos.cisco.com/master/doc/images/usecases/spacemanagement.jpg',
    link: '/doc/UseCases/SpaceManagement'
  },
  {
    id: 'hot-desking',
    text: 'Let your employees find a vacant desk quickly with the hot desking APIs',
    imgUrl: 'https://raw.githubusercontent.com/cisco-ce/roomos.cisco.com/master/doc/images/usecases/hotdesking.jpg',
    link: '/doc/UseCases/PeopleCount'
  },
  // {
  //   id: 'work-environment',
  //   text: 'Monitor the working environment, both in meeting room and home offices',
  //   imgUrl: 'https://raw.githubusercontent.com/cisco-ce/roomos.cisco.com/master/doc/images/usecases/environmental-sensors.png',
  //   link: '/doc/UseCases/EnvironmentalSensors'
  // },
  {
    id: 'virtual-receptionist',
    text: 'Provide a remote host when your reception is not staffed',
    imgUrl: 'https://raw.githubusercontent.com/cisco-ce/roomos.cisco.com/master/doc/images/kiosk/overview.png',
    link: '/doc/UseCases/Kiosk'
  },
  {
    id: 'room-controls',
    text: 'Control everything in the room with your Cisco Navigator touch panel',
    imgUrl: 'https://raw.githubusercontent.com/cisco-ce/roomos.cisco.com/master/doc/images/usecases/ui-extensions.png',
    link: '/doc/UseCases/RoomControls'
  },
  {
    id: 'digital-signage',
    text: 'Make the best use of your screens with digital signage',
    imgUrl: 'https://raw.githubusercontent.com/cisco-ce/roomos.cisco.com/master/doc/images/usecases/signage.png',
    link: '/doc/UseCases/DigitalSignage'
  },
];

const FrontPage = () => {
  const current = Math.floor(Math.random() * usecases.length);
  return (
    <main className="front-page">
      <Carousel items={usecases} current={current} />
      <footer>Build web applications, macros and integrations for the world's most open and flexible platform for video collaboration devices.</footer>
    </main>
  );
};

module.exports = FrontPage;
