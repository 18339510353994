const React = require('react');
const jsxapi = require('jsxapi');

const Dialog = require('../widgets/Dialog');

const buttonTexts = {
  'not-connected': 'Connect',
  'connecting': 'Connecting...',
  'connected': 'Disconnect',
  'failed': 'Connect',
};


const Connect = (props) => {
  const { connect, connection, connector, close, login } = props;
  const [host, setHost] = React.useState(login.host || '');
  const [username, setUsername] = React.useState(login.username || '');
  const [password, setPassword] = React.useState(login.password || '');
  const onConnect = () => connect({ host, username, password });
  const enabled = host && username && connection != 'connecting';

  const buttonText = buttonTexts[connection] || connection;
  const disconnect = () => connect(false);

  if (connection === 'connected') {
    return (
      <Dialog onClose={close}>
        <h2>You are connected!</h2>
        <div>{connector.unitName}</div>
        <div>{connector.unitType}</div>
        <div><a href={'https://' + login.host} target="_blank">{login.host}</a></div>
        <button className="primary" onClick={disconnect}>Disconnect</button>
      </Dialog>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onConnect();
  };

  return (
      <Dialog onClose={close}>
        <h2>Connect to your video device</h2>
        <form className="form connect" onSubmit={handleSubmit}>
          <div>
            This let's you install extensions easily. You need a local admin user.{' '}
            <a href="/doc/TechDocs/LocalAdminUser/" target="_blank">See guide</a> for more info.
          </div>
          <input
            placeholder="IP Address (eg 10.47.1.133)"
            value={host}
            onChange={e => setHost(e.target.value)}
          />
          <input
            placeholder="Username"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
          <input
            placeholder="Password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          {
            connection === 'failed' &&
            <b className="error">Not able to connect.
              <ul>
                <li>Try logging in at <a href={'https://' + host} target="_blank">{host}</a> and accept the self-signed certificate?
                </li>
                <li>Make sure you are on the same network (not VPN)</li>
                <li>DX80 etc: Make sure <i>xConfiguration NetworkServices WebSocket</i> is <b>FollowHTTPService</b></li>
              </ul>
            </b>
          }
          <button disabled={!enabled} className="primary" onClick={onConnect}>
            {buttonText}
          </button>
        </form>
      </Dialog>
  );
};

module.exports = Connect;
