const React = require('react');

const MasterDetail = (props) => {
  const { master, detail } = props;
  return (
    <div className="master-detail">
      <div className="master">
        {master}
      </div>
      <div className="detail">
        {detail}
      </div>
    </div>
  );
};

module.exports = MasterDetail;
