const React = require('react');
const Media = require('react-media').default;

const {
  BrowserRouter,
  Switch,
  Redirect,
  Route,
} = require('react-router-dom');
const WebRouter = BrowserRouter;

const config = require('../config');
const Header = require('./header/Header');
const Xapi = require('./xapi/Xapi');
const XapiMenu = require('./menu/XapiMenu');
const GuideMenu = require('./menu/GuideMenu');
const Article = require('./article/Article');
const ArticleList = require('./article/ArticleList');
const FeedbackForm = require('./FeedbackForm');
const Filters = require('./xapi/SearchBar');
const Support = require('./Support');
const Macros = require('./examples/Macros');
const MacroDetails = require('./examples/MacroDetails');
const Notification = require('./widgets/Notification');
const FrontPage = require('./FrontPage');
const MasterDetail = require('./MasterDetail');
const ExampleMenu = require('./menu/ExampleMenu');
const Connect = require('./connect/Connect');
const InstallDialog = require('./examples/InstallDialog');
const ImageSearch = require('./images/ImageSearch');
const Admin = require('./admin/Admin');
const XapiDiff = require('./xapi/diff/XapiDiff');

const Router = (props) => {
  const {
    notification, actions, options, filters, product, schema, searchWord, hasMTR,
  } = props;
  const { setOption, setSearchWord } = actions;

  const searchBar = (
    <Filters
      filters={filters}
      setOption={setOption}
      options={options}
      product={product}
      schema={schema}
      hasMTR={hasMTR}
      searchWord={searchWord}
      setSearchWord={setSearchWord}
    />
  );

  return (
    <WebRouter>
      <Switch>
        <Route path={["/print/:folder/:article"]}>
          <Article print />
        </Route>
        <Route path="/">
          <React.Fragment>
            <Media query="(orientation:landscape)">
              <RouterFullSize searchBar={searchBar} {...props} />
            </Media>
            <Media query="(orientation:portrait)">
              <RouterSmallScreen searchBar={searchBar} {...props} />
            </Media>
          </React.Fragment>
        </Route>
      </Switch>
      <Notification {...notification} />
    </WebRouter>
  );
};

const RouterSmallScreen = (props) => {
  const { domains, actions, nodes, options, schemas, searchBar, internal } = props;
  const { setOption } = actions;

  return (
    <div className="screen mobile">
      <WebRouter>
        <Header actions={actions} />
        <Switch>
          <Route exact path="/">
            <Redirect to="/doc/Welcome/Welcome" />
          </Route>
          <Route path={["/doc/:folder/:article"]}>
            <Article />
          </Route>
          <Route path={["/doc/:folder/"]}>
            <ArticleList />
          </Route>
          <Route path={['/doc']}>
            <GuideMenu openAll />
          </Route>
          <Route path={['/xapi']} exact>
            {searchBar}
            <XapiMenu domains={domains} />
          </Route>
          <Route path="/xapi/diff">
            <XapiDiff schemas={schemas} />
          </Route>
          <Route path={['/xapi/:path']}>
            {searchBar}
            <Xapi
              nodes={nodes}
              options={options}
              setOption={setOption}
              internal={internal}
            />
          </Route>
          <Route exact path="/macros">
            <Macros />
          </Route>
          <Route path="/macros/:name">
            <MacroDetails />
          </Route>
        </Switch>
      </WebRouter>
    </div>
  );
};

const RouterFullSize = (props) => {
  const {
    domains, nodes, actions, options, dialog, internal, schemas, searchBar, login, connection, connector,
  } = props;
  const { setOption, showDialog, connect, installExtension } = actions;
  const closeDialog = () => showDialog(false);

  return (
    <WebRouter>
      <div className="screen">
        <Header actions={actions} connection={connection} />
        <Switch>
          <Route exact path="/">
            <FrontPage />
          </Route>
          <Route path={["/doc/:folder/:article"]}>
            <MasterDetail
              master={<GuideMenu />}
              detail={<Article />}
            />
          </Route>
          <Route exact path="/doc/">
            <Redirect to="/doc/Welcome/Welcome" />
          </Route>
          <Route path={["/doc/:folder", "/doc/"]}>
            <MasterDetail
              master={<GuideMenu />}
              detail={<ArticleList />}
            />
          </Route>
          <Route path="/xapi/diff">
            <XapiDiff schemas={schemas} />
          </Route>
          <Route path={['/xapi/:path', '/xapi']}>
            {searchBar}
            <MasterDetail
              master={<XapiMenu domains={domains} />}
              detail={
                <Xapi
                  nodes={nodes}
                  options={options}
                  setOption={setOption}
                  internal={internal}
                />
              }
            />
          </Route>
          <Route exact path={["/macros/tag/:tag", "/macros"]}>
            <MasterDetail
              master={<ExampleMenu />}
              detail={<Macros />}
            />
          </Route>
          <Route path="/macros/:name">
            <MasterDetail
                master={<ExampleMenu />}
                detail={<MacroDetails installExtension={installExtension} />}
              />
          </Route>
          <Route path="/images">
            <ImageSearch connector={connector} connection={connection} actions={actions} />
          </Route>
          <Route path="/support">
            <Support />
          </Route>
          <Route path="/admin">
              <Admin actions={actions} />
          </Route>
        </Switch>
        {dialog && dialog.startsWith('connect') &&
          <Connect
            connect={connect}
            connection={connection}
            login={login}
            connector={connector}
            close={closeDialog}
          />
        }
        {
          dialog === 'install' &&
          <InstallDialog
            connection={connection}
            close={closeDialog}
            connector={connector}
            showDialog={showDialog}
            name={props.installExtension}
          />
        }
        { dialog === 'feedback' && <FeedbackForm close={closeDialog} /> }
      </div>
    </WebRouter>
  );
};


module.exports = Router;
