const React = require('react');
const { useHistory, useParams } = require('react-router-dom');

const { getMacros } = require('../../dataHelper');

function testTag(tag, list) {
  if (tag === 'All') return true;
  return list && list.toLowerCase().includes(tag.toLowerCase());
}

const Macros = (props) => {
  const history = useHistory();
  const { tag } = useParams();

  const show = url => {
    console.log(url);
    history.push(url);
  };

  const [ macroList, setMacros ] = React.useState([]);
  React.useEffect(() => {
    getMacros().then(list => {
      list.sort((m1, m2) => m1.title < m2.title ? -1 : 1);
      setMacros(list);
    });
  }, []);

  const filtered = tag ? macroList.filter(m => testTag(tag, m.tags)) : macroList;

  const list = filtered.map((m) => {
    const { title, description, symbol, url } = m;
    const open = () => {
      // external repo
      if (url) {
        window.open(url, '_blank');
      }
      else {
        show(`/macros/${title}`);
      }
    }

    return (
      <div className="card" key={title} onClick={open}>
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        <div className="symbol">{symbol}</div>
      </div>
    )
  });

  const macroTable = <div className="macros">{list}</div>;

  return (
    <main className="macro-list">
      <div className="page">
        <h1>Examples</h1>
        The macros and extensions below have been selected because they are useful either to solve real world problems or for learning purposes.

        They are hosted in the DevNet Macro Sample repository at Github.

        <br />
        <a href="https://github.com/CiscoDevNet/roomdevices-macros-samples" target="_blank" className="button">View on Github</a>

        {macroTable}
      </div>
    </main>
  );
};

module.exports = Macros;
