const { useEffect } = require('react');
const React = require('react');

const Menu = require('./Menu');

const ExampleMenu = (props) => {

  // TODO get tags from macro json list
  const tags = ['All', 'Audio', 'Home Office', 'Calling', 'Library', 'Peripherals', 'Privacy',
    'Meeting Room', 'Screensaver', 'Fun', 'Web'];

  const tagList = tags.map(tag => ({ name: tag, link: '/macros/tag/' + tag }));

  const menu = [{
    "name": "Macros",
    "items": tagList,
  }];

  const menu2= [
    {
      "name": "Macros",
      "link": "/macros/",
    },
    // {
    //   "name": "Macro Libraries",
    //   "link": "/macros/libraries",
    // },
    // {
    //   "name": "Macro Tutorials",
    //   "link": "/macros/tutorials",
    // },
    // {
    //   "name": "Node.js",
    //   "link": "/macros/node",
    // },
    // {
    //   "name": "Browser apps",
    //   "link": "/macros/browser",
    // },
  ];
  const { openAll } = props;


  return (
    <React.Fragment>
      <Menu name="Examples" sections={menu} openAll={true} />
    </React.Fragment>
  );
};

module.exports = ExampleMenu;