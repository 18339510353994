const React = require('react');

const QuickList = (props) => {
  const { items } = props;

  // no point showing quick list for short documents
  if (items.length < 3) {
    return null;
  }

  const list = items && items.map(i => (
    <a href={'#' + i.id} key={i.id}>{i.name}</a>
  ));

  return (
    <div className="quick-list">
      {list}
    </div>
  )
};

module.exports = QuickList;
