const products = {
 'bandai':'Desk Mini',
 'barents': 'Codec Pro',
 'barents_70d': 'Room 70 Dual G2',
 'barents_70i': 'Room 70 Panorama',
 'barents_70s': 'Room 70 Single G2',
 'barents_82i': 'Room Panorama',
 'brooklyn': 'Room Bar Pro',
'darling_10_55': 'Board 55',
 'darling_10_70': 'Board 70',
 'darling_15_55': 'Board 55S',
 'darling_15_70': 'Board 70S',
 'darling_15_85': 'Board 85S',
 'davinci': 'Room Bar',
 'felix_55': 'Board Pro 55 G2',
 'felix_75': 'Board Pro 75 G2',
 'helix_55': 'Board Pro 55',
 'helix_75': 'Board Pro 75',
 'dx70': 'DX70',
 'dx80': 'DX80',
 'havella': 'Room Kit Mini',
 'hopen': 'Room Kit',
 'millennium': 'Codec EQ',
 'mx200_g2': 'MX200 G2',
 'mx300_g2': 'MX300 G2',
 'mx700': 'MX700 (single cam)',
 'mx700st': 'MX700 (dual cam)',
 'mx800': 'MX800 (single cam)',
 'mx800d': 'MX800 Dual',
 'mx800st': 'MX800 (dual cam)',
 'octavio': 'Desk',
 'polaris': 'Desk Pro',
 'spitsbergen': 'Room 55',
 'svea': 'Codec Plus',
 'svea_55d': 'Room 55 Dual',
 'svea_70d': 'Room 70 Dual',
 'svea_70s': 'Room 70 Single',
 'sx10': 'SX10',
 'sx20': 'SX20',
 'sx80': 'SX80',
 'vecchio': 'Navigator',
};

function find(products, keywords) {
  const list = [];
  for (const key in products) {
    const name = products[key];
    const hit = keywords.some(keyword => name.includes(keyword));
    if (hit) {
      list.push({ key, name });
    }
  }
  list.sort((p1, p2) => p1.name < p2.name ? -1 : 1);
  return list;
}

function keyFromInternal(internal) {
  const product = products[internal]
  return product ? product.replaceAll(' ', '-') : internal
}

function internalFromKey(key) {
  const name = key?.replaceAll('-', ' ')
  return  Object.keys(products).find(id => products[id] === name) || key;
}

const productGroups = [
  { name: 'Room Series', products: find(products, ['Room', 'Codec']) },
  { name: 'Desk Series', products: find(products, ['DX', 'Desk']) },
  { name: 'Board Series', products: find(products, ['Board']) },
  { name: 'MX Series', products: find(products, ['MX']) },
  { name: 'SX Series', products: find(products, ['SX']) },
  { name: 'Navigator', products: find(products, ['Navigator']) },
];

function productName(name) {
  return products[name] || (`${name}`);
}

function findGroup(name) {
  const res = productGroups.find((group) => group.products.some(p => p.key === name));
  return res ? res.name : false;
}

function simplifyNames(products) {
  const nameWithGroup = products.map(p => `${findGroup(p)}: ${productName(p)}`);
  nameWithGroup.sort((i1, i2) => i1 < i2 ? -1 : 1);

  let stringResult = nameWithGroup.join(', ');

  productGroups.forEach((group) => {
    const fullGroupName = group.products
      .map(p => `${group.name}: ${productName(p.key)}`)
      .sort((i1, i2) => i1 < i2 ? -1 : 1)
      .join(', ');

    stringResult = stringResult.replace(fullGroupName, group.name);
  });

  // Now, all groups which are 'full' have been replaced by the name of the group
  // we need to remove the prefix from the individual products again:
  const result = stringResult
    .split(', ')
    .map(n => n.replace(/(.*\: )/, ''));

  const groupsFound = result.filter(r => r.includes('Series')).length;
  if (groupsFound >= productGroups.length) {
    return ['All products'];
  }

  return result;
}

module.exports = {
  productName,
  productGroups,
  internalFromKey,
  keyFromInternal,
  simplifyNames,
  names: products,
};
